.simple-footer
    width: 100%
    display: flex
    flex-direction: row
    align-items: center
    position: relative
    box-sizing: border-box
    padding: 10px 100px
    background-color: #014D74
    margin-top: auto

    @media (max-width: 768px)
        flex-direction: column
        align-items: center
        gap: 10px
        padding: 10px 20px

.links-section
    flex: 1
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    gap: 30px

    @media (max-width: 1024px)
        gap: 40px

    @media (max-width: 600px)
        justify-content: space-between
        gap: 30px

.link
    position: relative
    font-size: 14px
    font-family: 'Open Sans'
    color: #fff
    text-align: left

    &:hover
        color: #FF5522
        transform: scale(1.05)
    
    @media (max-width: 768px)
        font-size: 12px

.copyright-socials-section
    flex: 1
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-end

    @media (max-width: 600px)
        flex-direction: column
        align-items: center
        justify-content: center
        gap: 20px

.copyright
    height: 100%
    position: relative
    font-size: 14px
    font-family: 'Open Sans'
    color: #fff
    text-align: left
    cursor: pointer
    margin: 0

    &:hover
        color: #FF5522
        transform: scale(1.05)
    
    @media (max-width: 768px)
        font-size: 12px
.video-player-overlay
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background-color: rgba(0, 0, 0, 0.8)
    display: flex
    align-items: center
    justify-content: center
    z-index: 1000

.video-player-container
    width: 80%
    max-width: 800px
    height: auto
    max-height: 450px
    background-color: #000
    border-radius: 8px
    overflow: hidden
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3)

    @media (max-width: 768px)
        width: 90%
        height: 60%

.video
    width: 100%
    height: 100%
    border-radius: 8px

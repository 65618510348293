.report-carousel-card
    width: 100%
    position: relative
    border-radius: 16px
    min-height: 300px
    overflow: hidden
    background-color: #fff
    display: flex
    flex-direction: row
    justify-content: flex-start
    text-align: left
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1)

    @media (max-width: 1280px)
        flex-direction: column
        min-height: auto
        padding: 16px

    @media (max-width: 1024)
        flex-direction: column
        min-height: auto
        padding: 16px

    @media (max-width: 992px)
        flex-direction: column
        min-height: auto
        padding: 16px
        gap: 20px

.report-image
    flex: 1
    width: 100%
    position: relative
    display: flex
    background-size: cover
    background-repeat: no-repeat
    background-position: top
    align-items: flex-end
    box-sizing: inline-block

    @media (max-width: 1280px)
        height: auto
        background-position: center

.image-title
    flex: 1
    display: flex
    flex-direction: column
    justify-content: flex-end
    width: 100%
    font-size: 24px
    font-weight: 600
    font-family: 'Poppins'
    color: #fff
    text-align: left
    height: 100%
    padding: 32px
    background: rgba(0, 0, 0, 0.2)

    @media (max-width: 1280px)
        font-size: 22px
        padding: 24px

    @media (max-width: 1024px)
        font-size: 20px
        padding: 20px

.report-content
    width: 100%
    flex: 0.8
    position: relative
    background-color: #fff
    overflow: hidden
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: space-between
    padding: 16px
    box-sizing: border-box
    text-align: left
    
    @media (max-width: 768px)
        padding: 4px
        gap: 8px

.category
    border-radius: 4px
    padding: 4px
    font-size: 14px
    font-weight: 500
    font-family: 'Poppins'

    @media (max-width: 768px)
        font-size: 13px
        padding: 4px

.main-content
    display: flex
    width: 100%
    flex-direction: column
    justify-content: space-between
    align-items: flex-start
    gap: 16px

    @media (max-width: 768px)
        gap: 12px

.title
    position: relative
    font-size: 16px
    text-transform: uppercase
    font-weight: 600
    font-family: 'Poppins'
    color: #1b3242
    text-align: left
    display: inline-block

    @media (max-width: 768px)
        font-size: 14px

.description
    width: 100%
    position: relative
    font-size: 12px
    font-family: 'Open Sans'
    color: #1b3242
    text-align: left
    display: inline-block
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: 5
    -webkit-box-orient: vertical

    @media (max-width: 768px)
        font-size: 10px
        -webkit-line-clamp: 4


.country-picker-container
    width: 100%
    position: relative
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 20px
    min-width: 400px

    @media (max-width: 768px)
        min-width: 300px
        gap: 15px

    @media (max-width: 480px)
        min-width: 100%
        gap: 10px

.label
    color: #004264
    text-align: start
    font-size: 16px
    font-family: 'Poppins'
    font-weight: 500

    @media (max-width: 768px)
        font-size: 14px

    @media (max-width: 480px)
        font-size: 12px

.required
    color: #FF5522

.flag-select
    align-self: stretch
    width: 100%
    position: relative
    border-radius: 8px
    box-sizing: border-box
    height: 48px
    flex-shrink: 0
    color: #004264
    font-size: 16px
    font-family: 'Poppins'
    font-weight: 500

:global 
    button[id="rfs-btn"]
        cursor: pointer
        width: 100%
        height: 48px
        display: flex
        justify-content: space-between
        align-items: center
        padding: 0px 10px
        font-family: 'Poppins', sans-serif
        color: #004264
        border: 1px solid #e8e8e8
        border-radius: 8px
        background: #f7fbff

    @media (max-width: 768px)
        font-size: 14px
        height: 44px
        padding: 0px 8px

    @media (max-width: 480px)
        font-size: 12px
        height: 40px
        padding: 0px 5px

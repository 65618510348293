.prompt-display-container
    width: 100%
    min-height: 120px
    @media (max-width: 1024px)
        min-height: 140px  
    @media (max-width: 768px)
        min-height: 130px
        
    position: relative
    border-radius: 5px
    border: 1px solid #5c96b5
    box-sizing: border-box
    padding: 12px
    gap: 8px
    margin: 0 auto
    display: flex
    flex-direction: column
    justify-content: space-between
    transition: box-shadow 0.3s ease, transform 0.3s ease

.prompt-content-wrapper
    display: flex
    max-height: calc(100% - 60px) 
    flex-direction: column
    justify-content: flex-start
    align-content: flex-start
    position: relative
    flex: 1

.hint
    width: 100%
    position: relative
    font-weight: 300
    font-family: 'Open Sans'
    color: #707070
    font-size: 16px
    text-align: left
    display: inline-block
    @media (max-width: 768px)
        font-size: 14px

    @media (max-width: 576px)
        font-size: 12px

.prompt-bubble-list
    display: flex
    flex-wrap: wrap
    gap: 12px
    padding: 8px
    align-items: flex-start
    justify-content: flex-start
    overflow-y: auto

.prompt-bubble
    background-color: #e0f4ff
    padding: 8px 12px
    border-radius: 15px
    font-size: 16px
    text-align: left
    color: #216f97
    font-family: 'Open Sans'
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1)
    z-index: 1
    cursor: pointer
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, margin 0.3s ease

    &:hover
        background-color: #d1ecff
        transform: scale(1.02)
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15)
        margin: 0px 5px 5px 0px

.generate-button
    align-self: flex-end
    display: flex

// Spacing
$za-spacing-small: 10px // Small gap between.
$za-spacing-medium: 20px // Medium spacing for padding and margins.
$za-spacing-large: 30px // Large gap.
$za-spacing-extra-large: 50px // Prominent spacing in sections.
$za-padding-horizontal: 20px // Padding for elements like buttons or containers.
$za-padding-vertical: 10px // Padding for buttons and containers.
$za-gap-trusted-feature: 40px
$za-section-padding: 100px
$za-gap-section-default: 48px
$za-margin-16: 16-px
$za-spacing-section-padding: 26px 0
$za-spacing-section-padding-small: 16px 0
$za-spacing-gap-small: 20px
$za-spacing-gap-extra-small: 16px

.metrics-card
    display: flex
    flex-direction: row
    justify-content: space-between
    max-width: 1000px
    width: 100%
    align-items: center
    padding: 60px
    gap: 50px
    background-color: #fff
    box-shadow: 2px 2px 13px 0px #0055811A
    border-radius: 32px

    @media (max-width: 768px)
        flex-direction: column
        width: 90%
        padding: 30px
        gap: 30px

.metric
    flex: 1 1
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 20px

    @media (max-width: 768px)
        gap: 16px

.title
    font-family: Poppins
    font-size: 84px
    color: #005581
    font-weight: 300
    line-height: 144px
    text-align: center
    text-underline-position: from-font
    text-decoration-skip-ink: none

    @media (max-width: 768px)
        font-size: 64px
        line-height: 1.1

    @media (max-width: 480px)
        font-size: 48px
        line-height: 1

.description
    max-width: 358px
    width: 100%
    font-family: Open Sans
    font-size: 24px
    color: #005581
    font-weight: 300
    line-height: 32.68px
    text-align: center
    text-underline-position: from-font
    text-decoration-skip-ink: none
    white-space: normal
    overflow-wrap: break-word
    word-break: break-word
    display: inline-block

    @media (max-width: 768px)
        font-size: 20px
        line-height: 28px
        max-width: 100%

    @media (max-width: 480px)
        font-size: 18px
        line-height: 24px

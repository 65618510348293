@import "../global/_variables"

.header-section
    height: 100%
    flex-direction: column
    width: 100%
    align-items: center
    text-align: center
    background: transparent

    @media (max-width: 1024px)
        height: auto

.navigation-wrapper
    width: 100%

.main-wrapper
    height: 100vh
    background: linear-gradient(180deg, #005581 0%, #00131D 100%)
    overflow: hidden

    @media (max-width: 1024px)
        height: auto
        padding-bottom: 20px

.background-container
    width: 100%
    height: 100%
    z-index: 0
    background: url('/assets/images/svg/main-graph.svg')
    background-size: 100% 100%

    @media (max-width: 1024px)
        height: auto
        background-size: cover

.bot-wrapper
    position: relative
    top: -100px
    margin-top: -100px
    z-index: 1

    @media (max-width: 1280px)
        top: -100px
        margin-top: 0
        padding: 20px

    @media (max-width: 1024px)
        top: 0
        margin-top: 0
        padding: 20px
.topic-card
    width: 100%
    background-color: #fff
    position: relative
    border-radius: 8px
    min-height: 150px
    min-width: 350px
    overflow: hidden
    background-size: cover
    background-repeat: no-repeat
    background-position: top
    cursor: pointer
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1)
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out

    &:hover
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15)

    @media (max-width: 768px)
        min-width: 280px
        padding: 8px 0px 0px 0px

    @media (max-width: 480px)
        min-width: 200px
        padding: 5px 0px 0px 0px

.header, .body, .watermark
    padding: 10px

    @media (max-width: 768px)
        padding: 8px

    @media (max-width: 480px)
        padding: 5px

.header
    width: 100%
    display: inline-block
    font-family: 'Poppins'
    text-align: left

.highlight
    width: 100%
    color: #004264
    font-size: 20px
    font-weight: 700
    text-transform: uppercase

    @media (max-width: 768px)
        font-size: 18px
    
    @media (max-width: 480px)
        font-size: 16px

.title
    width: 100%
    color: #4E4E4E
    font-size: 18px
    font-weight: 400
    font-family: 'Open Sans'

    @media (max-width: 768px)
        font-size: 16px

    @media (max-width: 480px)
        font-size: 14px

.body
    display: flex
    align-items: flex-end
    padding: 0 10px 10px 0

    @media (max-width: 768px)
        padding: 0 8px 8px 0

    @media (max-width: 480px)
        padding: 0 5px 5px 0

.year
    width: 100%
    font-size: 48px
    color: #C8D9E2
    font-weight: 900
    text-align: right

    @media (max-width: 768px)
        font-size: 36px

    @media (max-width: 480px)
        font-size: 28px

.watermark
    display: flex
    justify-content: center
    align-items: center
    padding: 20px
    height: auto
    background: linear-gradient(90deg, #004264, #0085ca)

    @media (max-width: 768px)
        padding: 15px

    @media (max-width: 480px)
        padding: 10px

.logo
    width: auto
    height: 38px
    max-width: 100%
    object-fit: contain

    @media (max-width: 768px)
        height: 30px

    @media (max-width: 480px)
        height: 24px

.radio-group
    display: flex
    flex-direction: row
    flex-wrap: wrap
    gap: 40px

    @media screen and (max-width: 768px)
        gap: 20px

.radio-option
    display: flex
    align-items: center
    cursor: pointer
    font-size: 24px
    font-family: 'Poppins'
    font-weight: 400
    color: #282828

    @media screen and (max-width: 768px)
        font-size: 18px

.radio-input
    position: absolute
    opacity: 0

.radio-circle
    position: relative
    display: inline-flex
    align-items: center
    justify-content: center
    width: 20px
    height: 20px
    border: 2px solid #ff5522
    border-radius: 50%
    margin-right: 10px

    &:before
        content: ''
        width: 10px
        height: 10px
        border-radius: 50%
        background-color: transparent
        transition: background-color 0.3s ease
        

.radio-input:checked + .radio-circle:before
    background-color: #FF5522
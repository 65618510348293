.download-report-card
    padding: 20px 0px 0px 0px
    display: flex
    position: relative
    width: 80%
    max-width: 1000px
    height: auto
    margin: 0 auto
    align-items: center
    justify-content: space-between
    align-content: flex-start
    flex-direction: column
    box-sizing: border-box
    background: rgba(255, 255, 255, 1)
    background-size: cover
    background-repeat: no-repeat
    background-position: top
    text-align: center
    font-size: 24px
    border-radius: 32px
    overflow: hidden

    @media (max-width: 820px)
        width: 100%
        padding: 20px 0px 0px 0px
        font-size: 18px
        border-radius: 0
        aspect-ratio: auto

.header
    width: 100%
    padding: 0px 64px 0px 0px
    display: flex
    flex-direction: row
    align-items: flex-end
    justify-content: flex-end

    @media (max-width: 820px)
        display: flex
        max-width: 100%
        padding: 0px 20px 0px 0px

.close-button
    position: relative
    width: auto
    margin: 0 !important
    background: none
    border: none
    cursor: pointer
    display: flex
    align-items: flex-end
    justify-content: flex-end
    color: rgba(33, 111, 151, 1) !important
    
    &:hover
        color: #FF5522 !important

.close-icon
    font-size: 30px
    color: rgba(33, 111, 151, 1) !important
    cursor: pointer

    &:hover
        color: #FF5522 !important

.main-content
    width: 100%
    padding: 0px 64px 64px 64px
    position: relative
    display: flex
    flex-direction: column
    gap: 16px
    align-items: flex-start
    justify-content: flex-start
    text-align: left

    @media (max-width: 820px)
        display: flex
        max-width: 100%
        padding: 0px 20px 20px 20px
        align-items: center
        text-align: center
        justify-content: center
        flex-direction: column

.title-wrapper
    width: 100%
    display: flex
    flex-direction: row
    align-items: center
    gap: 12px
    justify-content: flex-start
    border-bottom: 0.8px solid #e3e3e3

    @media (max-width: 820px)
        display: flex
        max-width: 100%
        align-items: center
        text-align: center
        justify-content: center
        flex-direction: column


.logo-container
    width: auto
    display: flex
    flex-direction: row
    align-items: center
    gap: 12px
    justify-content: flex-start

.zerone-logo
    width: 100px
    height: auto

    @media (max-width: 820px)
        width: 80px

.title
    width: 100%
    font-size: 24px
    font-weight: 300
    color: #005581
    margin: 0
    padding: 0
    text-align: left
    padding: 16px 0

    @media (max-width: 820px)
        text-align: center
        font-size: 18px

.message-wrapper
    width: 100%
    gap: 32px
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start

    @media (max-width: 820px)
        gap: 16px


.message
    width: 100%
    font-size: 20px
    font-weight: 300
    color: #005581
    margin: 0
    padding: 0
    text-align: left
    padding: 0px

    @media (max-width: 820px)
        text-align: center
        font-size: 18px

.note
    width: 100%
    font-size: 18px
    font-weight: 300
    color: #005581
    margin: 0
    padding: 0
    text-align: left
    padding: 0px

    @media (max-width: 820px)
        text-align: center
        font-size: 14px

.footer
    width: 100%
    padding: 20px 64px 20px 64px
    display: flex
    background: rgba(33, 111, 151, 1) !important
    flex-direction: row
    align-items: center
    justify-content: space-between
    gap: 16px
    text-align: left
    font-size: 14px
    color: #e3e3e3
    font-family: 'Poppins'

    @media (max-width: 820px)
        display: flex
        max-width: 100%
        padding: 20px 20px 20px 20px
        align-items: center
        text-align: center
        justify-content: center
        flex-direction: column

    & > p
        width: 100%
        text-align: center
        margin-bottom: 0
.paragraph
    width: 100%
    padding: 10px 0px
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 8px

.section, .title
    font-size: 32px
    font-family: 'Poppins'
    color: #005581

.title
    font-size: 24px
    font-weight: 400

.main-content
    flex: 1
    position: relative
    width: 100%
    height: auto
    overflow: hidden
    display: flex
    flex-direction: column
    align-items: center
    align-content: space-between
    box-sizing: border-box

    @media (max-width: 1024px)
        height: auto
        flex-direction: column
        align-items: center

    @media (max-width: 768px)
        height: auto
        flex-direction: column
        text-align: center

.left-section
    flex: 1
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    width: 100%
    gap: 32px
    text-align: left

    @media (max-width: 1024px)
        display: flex
        max-width: 100%
        text-align: left

    @media (max-width: 768px)
        max-width: 100%
        text-align: left

.text
    position: relative
    font-family: 'Open Sans'
    font-size: 20px
    line-height: 1.8
    height: 100%
    color: #282828

    @media (max-width: 1024px)
        line-height: 1.5
        font-size: 18px

    @media (max-width: 768px)
        line-height: 1.5
        font-size: 18px

.highlight
    color: #FF5522
    font-weight: 700

.image
    position: relative
    max-width: 40%

    @media (max-width: 1024px)
        max-width: 80%

    @media (max-width: 768px)
        max-width: 100%

.right-section
    flex: 1
    max-width: 40%
    border-radius: 16px
    position: relative
    overflow: hidden
    width: 100%
    display: flex
    justify-content: flex-end
    align-items: center

    @media (max-width: 1024px)
        max-width: 100%
        margin-top: 32px
        min-height: 400px
        justify-content: center

    @media (max-width: 768px)
        min-height: 200px
        max-width: 100%
        justify-content: center
        margin-top: 32px

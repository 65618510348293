.pricing-hero-container
    margin: 0 auto
    position: relative
    width: 100%
    max-width: 1400px
    height: auto
    overflow: hidden
    display: flex
    flex-direction: column
    align-content: center
    justify-content: space-between
    align-items: center
    gap: 40px
    padding: 100px 40px

    @media (max-width: 1024px)
        padding: 60px 20px
        gap: 30px

    @media (max-width: 768px)
        padding: 40px 20px
        gap: 20px

.short-intro
    position: relative
    font-size: 24px
    font-family: 'Poppins'
    color: #707070
    text-align: center
    font-weight: 400

    @media (max-width: 1024px)
        font-size: 22px

    @media (max-width: 768px)
        font-size: 20px

.focus
    position: relative
    font-size: 52px
    font-family: 'Poppins'
    font-weight: 600
    color: #005581
    text-align: center

    @media (max-width: 1024px)
        font-size: 42px

    @media (max-width: 768px)
        font-size: 36px

.line-break
    display: flex
    width: 100%
    white-space: nowrap

    @media (max-width: 760px)
        display: none

.discount
    display: flex
    flex-direction: row
    gap: 8px
    font-size: 16px
    font-weight: 400
    color: #005581

    @media (max-width: 1024px)
        font-size: 15px

    @media (max-width: 768px)
        font-size: 14px
.price-card
    position: relative
    align-self: stretch
    width: 100%
    max-width: 350px
    padding: 32px
    border-radius: 8px
    background-color: #fff
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1)
    display: flex
    flex-direction: column
    flex-shrink: 0
    align-items: flex-start
    box-sizing: border-box
    gap: 32px
    text-align: center
    margin: 0 auto
    transition: transfrom 0.3s ease, box-shadow 0.3s ease

    &:hover
        transform: scale(1.05)
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2)

    @media (max-width: 1280px)
        max-width: 400px

    @media (max-width: 1200px)
        max-width: 300px

    @media (max-width: 1024px)
        max-width: 250px
        width: 250px
        padding: 24px
        gap: 24px

    @media (max-width: 768px)
        max-width: 200px
        width: 100%
        padding: 20px
        gap: 20px
        text-align: left

    @media (max-width: 480px)
        max-width: 100%
        padding: 16px
        gap: 16px

.subscription-type-container
    position: relative
    width: 100%
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: center
    gap: 16px

.subscription-type
    font-size: 20px
    font-weight: 700
    text-align: center
    font-family: 'Open Sans'

.basic
    color: #216F97

.sapphire
    color: #003FD1

.gold
    color: #E88B00

.platinum
    color: #BCBCBC

.more-info
    position: relative
    font-size: 16px
    font-family: 'Open Sans'
    color: #707070
    text-align: start

    @media (max-width: 768px)
        text-align: left

.price-container, .price-change-container
    position: relative
    width: 100%
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 16px

.price-change-container
    gap: 4px

.price
    position: relative
    font-size: 36px
    text-align: center
    font-family: 'Poppins'
    color: #005581
    font-weight: 700

    @media (max-width: 768px)
        text-align: left

.old-price
    position: relative
    font-size: 18px
    text-align: center
    font-family: 'Poppins'
    color: #005581
    text-decoration: line-through
    font-weight: 700

    @media (max-width: 768px)
        text-align: left

.term
    position: relative
    font-size: 16px
    font-family: 'Open Sans'
    color: '#707070'
    text-align: center

    @media (max-width: 768px)
        text-align: left

.feature-list
    position: relative
    width: 100%
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 16px

.feature-item
    position: relative
    width: 100%
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    gap: 24px
    text-align: left
    font-family: 'Open Sans'
    color: #000000
    font-size: 16px

.check-icon
    overflow: hidden
    color: #005581
    width: 24px
    height: 24px
    flex-shrink: 0
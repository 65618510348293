@import "../global/_variables"

.header-section
    height: 100%
    flex-direction: column
    width: 100%
    align-items: center
    text-align: center
    background: transparent

    @media (max-width: 1024px)
        height: auto

.navigation-wrapper
    width: 100%

.main-wrapper
    height: 100%
    background: linear-gradient(180deg, #ECF9FF 0%, #ECF9FF 100%)
    overflow: hidden

    @media (max-width: 1024px)
        height: auto
        padding-bottom: 20px

.background-container
    width: 100%
    height: 100%
    padding-bottom: 100px
    z-index: 0
    background: url('/assets/images/svg/main-graph.svg') no-repeat bottom
    background-size: cover

    @media (max-width: 1024px)
        padding-bottom: 20px
        height: auto
        background-size: cover

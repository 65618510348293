.landing-page
    background-color: #f9f9f9
    display: flex
    width: 100%
    max-width: 100%
    flex-direction: column
    align-content: center
    justify-content: center
    align-items: center

.section-line
    width: 60%
    max-width: 60%
    border: 1px solid #D9D9D9
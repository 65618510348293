@import "../global/variables"
.contact-us-page
    width: 100%
    height: 100%
    min-height: 80vh
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding-bottom: 30px
    z-index: 0
    background: url('/assets/images/svg/main-graph.svg') no-repeat bottom
    background-size: cover

    @media (max-width: 1024px)
        padding-bottom: 20px
        height: auto
        background-size: cover


.intro-text
  font-family: 'Open Sans', sans-serif
  font-size: 16px
  color: #707070
  line-height: 24px
  text-align: center
  margin-bottom: 30px

.contact-content
  display: flex
  justify-content: space-between
  align-items: flex-start
  gap: 40px

.image-container
  flex: 1
  max-width: 50%

.our-reach-image
  width: 100%
  height: auto
  object-fit: cover
  border-radius: 8px


@media (max-width: 768px)
  .contact-us-page
    padding: 30px

  .contact-content
    flex-direction: column
    align-items: center

  .image-container
    max-width: 100%
    margin-bottom: 30px

@media (max-width: 480px)
  .contact-us-page
    padding: 20px

  .intro-text
    font-size: 14px

//attempting to remove bottom margins and gap for the title and subtitle but this is not working so I adjusted the SectionHeader margin-bottom proprties.
// .contact-section-header
//     margin-bottom: 0px  

//     :global
//         .title-wrapper
//             gap: 0px  // Reduce the gap between title and subtitle

//         .section-title
//             margin-bottom: 0px  // Reduce the margin below the title

//         .section-subtitle
//             margin-top: 0  // Remove top margin from subtitle
.footer-section
    display: flex
    flex-direction: column
    align-items: center
    height: 100%
    width: 100%
    background-color: transparent
    background-image: url('/assets/images/png/dot-grid.png')
    background-size: cover
    background-repeat: no-repeat
    background-position: top

    .contact-us-wrapper
        width: 100%
        max-width: 1200px
        position: relative
        z-index: 2
        margin-bottom: -80px

        @media (max-width: 768px)
            margin-bottom: 0px
            max-width: 100%
            

    .footer-main-wrapper
        width: 100%

        @media (max-width: 768px)
            max-width: 100% 
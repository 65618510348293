.zerone-bot-beta-container
    width: 100%
    min-height: 120px
    @media (max-width: 1024px)
        min-height: 140px  
    @media (max-width: 768px)
        min-height: 130px
    position: relative
    border-radius: 5px
    border: 1px solid #5c96b5
    box-sizing: border-box
    overflow: hidden
    padding: 20px
    margin: 0 auto
    display: flex
    flex-direction: column
    transition: box-shadow 0.3s ease, transform 0.3s ease

    @media (max-width: 768px)
        padding: 15px

.loader-wrapper
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

.report
    background-color: transparent
    border: 1px solid #d1e3f0
    border-radius: 8px
    padding: 25px
    width: 100%
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05)
    font-family: 'Poppins', sans-serif
    color: #333
    line-height: 1.6

    @media (max-width: 768px)
        padding: 15px

.report-description
    font-size: 20px
    font-weight: 500
    color: #005581
    margin-bottom: 20px
    text-align: left

    @media (max-width: 768px)
        font-size: 18px
        margin-bottom: 15px

.report-content
    padding: 0
    margin: 0
    text-align: left

.report-paragraph
    font-size: 18px
    color: #333
    margin-bottom: 10px

    strong
        font-weight: 600
        color: #136a97

    @media (max-width: 768px)
        font-size: 16px

.topic-percentage
    font-size: 16px
    color: #ff5522
    font-weight: 500

    @media (max-width: 768px)
        font-size: 14px

.report-chart
    margin-top: 25px
    display: flex
    flex-direction: column
    align-items: center

    @media (max-width: 768px)
        margin-top: 20px

.chart-title
    font-size: 18px
    font-weight: 600
    color: #005581
    margin-bottom: 15px

    @media (max-width: 768px)
        font-size: 16px
        margin-bottom: 10px
.dynamic-card-info
  width: 100%
  max-width: 400px
  padding: 32px
  position: relative
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start
  min-width: 350px
  gap: 16px
  background: #fff
  border-radius: 10px
  overflow: hidden
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
  transition: transform 0.3s ease
  text-align: left
  cursor: pointer

  &:hover
    transform: translateY(-5px)

  @media (max-width: 768px)
    max-width: 100%
    padding: 24px
    flex-direction: column
    min-width: unset
    gap: 12px

  @media (max-width: 480px)
    padding: 16px
    gap: 8px

.title
    width: 100%
    font-family: 'Poppins'
    font-size: 20px
    color: #005581
    font-weight: 600
    line-height: 64px
    text-align: left
    text-underline-position: from-font
    text-decoration-skip-ink: none

    @media (max-width: 768px)
        font-size: 18px
        line-height: 1.4

    @media (max-width: 480px)
        font-size: 16px

.description
    width: 100%
    font-family: 'Open Sans'
    font-size: 16px
    color: #707070
    font-weight: 300
    line-height: 21.79px
    text-align: left
    text-underline-position: from-font
    text-decoration-skip-ink: none

    @media (max-width: 768px)
        font-size: 14px
        line-height: 1.4

    @media (max-width: 480px)
        font-size: 12px

.icon
    width: 100%
    height: 48px
    max-width: 48px

    @media (max-width: 768px)
        max-width: 36px
        height: 36px

    @media (max-width: 480px)
        max-width: 24px
        height: 24px
.profile-page
    width: 100%
    flex-direction: column
    align-content: center
    justify-content: center
    align-items: center
    overflow: hidden

.loader
    width: auto
    height: auto
    position: absolute
    top: 50%
    left: 50%
    z-index: 1000
    transform: translate(-50%, -50%)

    @media (max-width: 768px)
        width: 100%
        height: 100%
        top: 0
        left: 0
        transform: none
@import "../global/variables"

.compare-plans-table
  color: #282828
  width: 100%
  border-collapse: separate 
  border-spacing: 10px 
  text-align: center

  @media (max-width: 1024px)
    font-size: 18px 

  @media (max-width: 992px)
    display: none

.check-icon
  display: inline-block
  vertical-align: center
  color: #FF5522

thead th
  text-transform: uppercase
  padding: 10px
  font-size: 24px
  font-family: 'Poppins'
  font-weight: 600

  @media (max-width: 1024px)
    font-size: 20px 
    padding: 8px

tbody td
  padding: 10px
  font-family: 'Poppins'
  align-self: stretch
  font-weight: 400
  justify-content: start

  @media (max-width: 1024px)
    font-size: 18px
    padding: 8px

tr
  position: relative
  flex-direction: row
  padding: 20px
  box-sizing: border-box

  @media (max-width: 1024px)
    padding: 15px 10px

tbody tr:nth-child(odd) td 
  background-color: #def4ff

.compare-plans-table td:not(:first-child)
  width: 15% 
  font-size: 20px
  padding: 20px 0px 20px 0px

  @media (max-width: 1024px)
    font-size: 18px
    padding: 10px

.compare-plans-table td:first-child
  text-align: left
  font-size: 24px
  padding: 20px
  width: 40%

  @media (max-width: 1024px)
    font-size: 20px
    padding: 12px

.compare-plans-table .left-align
  text-align: left

.animated-background
    width: 100%
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    overflow: hidden

.lottie-animation
    position: absolute
    top: 0
    left: 0
    width: 100%
    max-width: 100%
    height: 100%
    z-index: 0
    pointer-events: none

    @media (max-width: 768px)
        height: auto
        max-height: 60vh

    @media (max-width: 480px)
        max-height: 50vh

.main-content
    position: relative
    z-index: 1
    flex: 1
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100%
    padding: 20px

    @media (max-width: 768px)
        padding: 15px

    @media (max-width: 480px)
        padding: 10px
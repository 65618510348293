.categories-content-section
    width: 100%
    align-items: center
    padding: 50px 60px
    text-align: center
    background-color: #f9f9f9

    @media (max-width: 768px)
        padding: 15px 20px

    @media (max-width: 576px)
        padding: 10px 15px
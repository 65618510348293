.report-summary
    position: relative
    width: 100%
    height: auto
    overflow: hidden
    display: flex
    flex-direction: row
    align-content: flex-start
    justify-content: space-between
    align-items: center
    box-sizing: border-box
    background-size: cover
    background-position: top
    background-repeat: no-repeat
    padding: 60px 100px

    @media (max-width: 1024px)
        height: auto
        flex-direction: column
        padding: 40px 60px
        align-items: center

    @media (max-width: 768px)
        flex-direction: column
        padding: 40px 20px

.main-wrapper
    flex: 1
    margin: 0 auto
    position: relative
    width: 100%
    height: auto
    overflow: hidden
    display: flex
    flex-direction: row
    align-content: flex-start
    justify-content: space-between
    align-items: center
    box-sizing: border-box

    @media (max-width: 1024px)
        height: auto
        flex-direction: column
        align-items: center

    @media (max-width: 768px)
        height: auto
        flex-direction: column
        text-align: center

.left-section
    flex: 1
    display: flex
    max-width: 50%
    position: relative
    flex-direction: column
    align-items: flex-start
    text-align: left
    gap: 32px

    @media (max-width: 1024px)
        display: flex
        max-width: 100%
        align-items: flex-start
        text-align: left

    @media (max-width: 768px)
        max-width: 100%
        align-items: flex-start
        text-align: left

.top
    position: relative
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    text-align: left
    font-family: 'Open Sans'
    gap: 16px
    flex-wrap: wrap
    width: auto

.category
    display: flex
    flex-wrap: wrap
    border-radius: 8px
    background-color: #1c5698
    padding: 0px 20px
    flex-shrink: 0
    color: #fff
    font-size: 16px
    text-transform: uppercase
    font-family: 'Open Sans'
    font-weight: 400
    line-height: 40px

    @media (max-width: 768px)
        font-size: 14px

.date-wrapper
    display: flex
    position: relative
    flex-direction: row
    align-items: center
    justify-content: flex-start
    height: 48px
    margin: auto
    gap: 16px
    font-weight: 700
    color: #707070

.separator
    position: relative
    width: 10px
    color: #707070

    @media (max-width: 768px)
        width: 8px

.date
    display: flex
    flex-direction: column
    position: relative
    font-size: 16px
    font-family: 'Open Sans'
    color: #707070
    font-weight: 400
    text-align: left

    @media (max-width: 768px)
        font-size: 14px

.title
    width: 100%
    position: relative
    font-size: 32px
    font-weight: 600
    font-family: 'Poppins'
    color: #1e1e1e
    text-align: left
    display: inline-block

    @media (max-width: 768px)
        font-size: 24px

.catchy-phrase
    width: 100%
    position: relative
    font-size: 16px
    font-family: 'Open Sans'
    color: #282828
    text-align: left
    display: inline-block
    font-weight: 400

.author
    position: relative
    width: 100%
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    gap: 16px
    text-align: left
    font-size: 16px
    color: #707070
    font-family: 'Open Sans'

.author-image
    width: 40px
    position: relative
    height: 40px
    border-radius: 30.5px
    object-fit: cover

.author-name
    gap: 8px
    font-weight: 700

.name-role
  margin: 0

.right-section
    flex: 1
    max-width: 40%
    padding: 4px
    position: relative
    overflow: hidden
    width: 100%
    display: flex
    justify-content: flex-end
    align-items: center

    @media (max-width: 1024px)
        max-width: 60%
        margin-top: 32px
        min-height: 400px
        justify-content: center

    @media (max-width: 768px)
        min-height: 200px
        max-width: 80%
        justify-content: center
        margin-top: 32px
.blog-post-content
    position: relative
    width: 100%
    height: auto
    overflow: hidden
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    padding: 60px 100px
    box-sizing: border-box


    @media (max-width: 1024px)
        padding: 40px 60px

    @media (max-width: 768px)
        padding: 40px 20px

.reference-wrapper
    width: 100%
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    gap: 12px
    font-size: 24px
    font-family: 'Poppins'
    color: #005581
    font-weight: 400

.reference
    font-size: 16px
    color: #0077a3
    text-decoration: none
    cursor: pointer
    
    &:hover
        text-decoration: underline
        color: #005581

.footer
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    padding: 12px 0
    font-size: 14px
    font-family: 'Poppins'
    color: #707070
    border-top: 1px solid #d8d8d8
    margin-top: 20px

.attribution-link
    font-size: 12px
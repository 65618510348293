.tracker-container
    margin: auto
    display: flex
    align-items: center
    background-color: #e0e0e0
    border-radius: 50px
    padding: 8px 12px
    gap: 16px
    width: fit-content
    height: 30px

.tracker-circle
    width: 8px
    height: 8px
    border-radius: 50%
    background-color: #bfbfbf
    transition: background-color 0.3s ease

.active
    background-color: #000
.carousel
    width: 100%
    max-width: 90vw
    overflow: hidden
    display: flex
    flex-direction: column
    justify-content: center
    gap: 8px

    @media (max-width: 1024px)
        gap: 16px

.carousel-container
    display: flex
    overflow: hidden
    justify-content: center
    align-items: center
    width: 100%
    height: 400px
    cursor: pointer

    @media (max-width: 1024px)
        flex-direction: column
        height: auto
        padding: 20px

.carousel-card
    flex: 0 0 33.33%
    transition: transform 0.5s ease, z-index 0.8s ease
    transform-origin: center
    pointer-events: auto

    &.center
        z-index: 2
        transform: scale(1.1) translateX(0)

    &.left
        z-index: 1
        transform: scale(0.95) translateX(-1%)

    &.right
        z-index: 1
        transform: scale(0.95) translateX(1%)

    @media (max-width: 1024px)
        flex: 0 0 100%
        transition: none
        transform: none
        z-index: 1
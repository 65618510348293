@import "../global/variables"

.sales-solution-card
    padding: 100px 0px 100px 100px
    display: flex
    width: 100%
    max-width: 1200px
    height: auto
    aspect-ratio: 2.5 / 1
    margin: 0 auto
    align-content: center
    flex-direction: row
    justify-content: center
    align-items: center
    overflow: hidden
    box-sizing: border-box
    background: linear-gradient(98.6deg, #014365, #012437)
    background-size: cover
    background-repeat: no-repeat
    background-position: top
    text-align: center
    font-size: 24px
    border-radius: 32px

    @media (max-width: 1024px)
        padding: 40px 30px
        font-size: 22px
        flex-direction: column
        aspect-ratio: 1.5 / 1
        align-items: center

    @media (max-width: 820px)
        padding: 20px 10px
        font-size: 18px
        border-radius: 0
        aspect-ratio: auto

.left-wrapper
    flex: 1
    max-width: 90%
    height: auto
    position: relative
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: flex-start

    @media (max-width: 1024px)
        display: flex
        max-width: 100%
        align-items: center
        text-align: center

    @media (max-width: 768px)
        max-width: 100%
        align-items: center
        text-align: center

.text-wrapper
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 12px
    justify-content: space-between

    @media (max-width: 1024px)
        align-items: center
        text-align: center

    @media (max-width: 768px)
        align-items: center
        text-align: center

.opening-text
    font-size: 20px
    font-family: 'Open Sans'
    text-align: left
    color: #BEF3F9
    font-weight: 400

    @media (max-width: 1024px)
        font-size: 18px
        text-align: center

    @media (max-width: 768px)
        font-size: 16px
        text-align: center

.main-title
    font-size: 36px
    line-height: 64px
    text-align: left
    color: #FFFFFF
    font-weight: 600
    font-family: 'Poppins'

    @media (max-width: 1024px)
        font-size: 30px
        line-height: 48px
        text-align: center

    @media (max-width: 768px)
        font-size: 24px
        line-height: 36px
        text-align: center

.description-text
    font-size: 20px
    line-height: 32px
    font-weight: 400
    font-family: 'Open Sans'
    color: #FFFFFF
    text-align: left

    @media (max-width: 1024px)
        font-size: 18px
        line-height: 28px
        text-align: center

    @media (max-width: 768px)
        font-size: 16px
        line-height: 24px
        text-align: center

.right-wrapper
    flex: 1
    max-width: 40%
    position: relative
    overflow: hidden
    margin-right: -10%
    height: auto

    @media (max-width: 1024px)
        max-width: 100%
        margin-right: 0
        margin-top: 20px

.banner-image
    width: 100%
    max-width: 373px
    height: auto
    max-height: 365px
    object-fit: contain

    @media (max-width: 1024px)
        max-width: 250px
        max-height: 250px

    @media (max-width: 768px)
        max-width: 250px
        max-height: 250px


.search-bar
    height: 100%
    max-height: 48px
    max-width: 400px
    width: 100%
    position: relative
    border-radius: 5px
    background-color: #fbfbfb
    border: 1px solid #5c96b5
    box-sizing: border-box
    overflow: hidden
    margin: 0
    flex-shrink: 0
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    padding: 0px 20px
    text-align: left
    gap: 4px
    transition: all 0.3s ease-in-out

    &:hover
        border-color: #216f97
        box-shadow: 0 4px 8px #c3e0f0
        transform: scale(1.01)

    @media (max-width: 768px)
        padding: 0px 10px

    @media (max-width: 480px)
        padding: 0px 8px
        align-items: stretch

.search-input
    height: 100%
    max-height: 40px
    display: flex
    align-items: center
    margin: auto auto
    font-size: 16px
    outline: none
    background: transparent
    text-align: left
    font-family: 'Open Sans'
    font-size: 16px
    color: #5c96b5
    font-weight: 400
    border: none
    flex-grow: 1
    margin-right: 0px
    transition: color 0.3s ease-in-out, width 0.3s ease-in-out

    &:focus
        color: #216f97
        width: 100%

    @media (max-width: 768px)
        font-size: 14px
        height: 35px

    @media (max-width: 480px)
        font-size: 12px
        height: 30px

.search-icon
    height: 30px
    width: 30px
    flex-shrink: 0
    margin: auto auto
    display: flex
    align-items: center
    justify-content: center

    @media (max-width: 768px)
        height: 25px
        height: 25px

    @media (max-width: 480px)
        height: 25px
        height: 25px
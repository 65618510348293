.basic-info
    position: relative
    width: 100%
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 40px
    text-align: left
    font-size: 20px
    padding-bottom: 40px
    font-family: 'Poppins'

    @media (max-width: 1024px)
        display: flex
        max-width: 100%
        align-items: flex-start
        text-align: left

    @media (max-width: 768px)
        max-width: 100%
        align-items: flex-start
        text-align: start

.basic-info-item
    display: flex
    flex-direction: column
    gap: 8px
    width: 100%
    height: auto
    margin: 0
    position: relative
    align-items: flex-start
    justify-content: flex-start
    text-align: left
    flex: 1

    @media (max-width: 1024px)
        display: flex
        max-width: 100%
        align-items: flex-start
        text-align: left

    @media (max-width: 768px)
        max-width: 100%
        width: 100%
        align-items: flex-start
        text-align: start

.left
    align-items: flex-end

.label, .value
    width: 100%
    position: relative
    font-size: 20px
    font-weight: 600
    margin: 0
    font-family: 'Poppins'
    color: #1e1e1e
    text-align: left
    display: inline-block

    @media (max-width: 768px)
        font-size: 16px

.label
    color: #707070
    font-size: 18px
    font-family: 'Open Sans'
    font-weight: 300
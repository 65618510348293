.secondary-button
    position: relative
    border-radius: 5px
    border: 2px solid
    min-height: 48px
    overflow: hidden
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    padding: 0px 20px
    box-sizing: border-box
    gap: 8px
    text-align: left
    font-size: 18px
    font-family: 'Poppins'
    transition: all 0.3s ease
    cursor: pointer
    line-height: 1.5
    letter-spacing: 0.5px

    .span
        font-size: 20px

    .button-icon
        width: 24px
        height: 24px
        object-fit: contain

    &:hover
        background-color: var(--hover-background-color)
        color: var(--hover-text-color)
        border-color: var(--hover-border-color)

    @media (min-width: 1440px)
        font-size: 20px
        padding: 16px 24px

    @media (max-width: 1024px)
        font-size: 18px
        padding: 10px 16px
        gap: 6px

        .button-icon
            width: 22px
            height: 22px

    @media (max-width: 768px)
        font-size: 16px
        padding: 8px 14px
        gap: 5px

        .button-icon
            width: 20px
            height: 20px

    @media (max-width: 480px)
        font-size: 14px
        padding: 6px 12px
        gap: 4px

        .button-con
            width: 18px
            height: 18px
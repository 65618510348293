.pricing-container
    position: relative
    width: 90%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    align-content: center
    overflow: hidden
    gap: 40px
    margin: 0 auto

    @media (max-width: 1024px)
        width: 95%

    @media (max-width: 576px)
        width: 100%

.pricing-content
    position: relative
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 40px

    @media (max-width: 576px)
        width: 90%

.tab-content
    position: absolute
    top: 0
    left: 0
    right: 0
    opacity: 0
    visibility: hidden
    transform: scale(0.98)
    transition: opacity 0.5s ease, transform 0.5s ease, visibility 0.3s ease
    display: flex
    justify-content: center
    align-items: center

    &.active
        opacity: 1
        visibility: visible
        transform: scale(1)
        position: relative
        
        @media (max-width: 768px)
            position: relative
            opacity: 1
            visibility: visible
            transform: none
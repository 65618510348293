.report-details-card
    width: parent
    position: relative
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
    border-radius: 8px
    background-color: #fff
    overflow: hidden
    flex-shrink: 0
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 30px 25px
    box-sizing: border-box
    gap: 30px
    text-align: left
    font-size: 16px
    color: #216f97
    font-family: 'Poppins'

.header
    width: 100%
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center

.share-button
    position: relative
    width: auto
    margin: 0 !important
    background: #216f97
    padding: 8px
    cursor: pointer
    display: flex
    align-content: center
    justify-content: center
    border-radius: 50%
    transition: transform 0.3s ease

    &:hover
        transform: scale(1.1)

.share-icon
    font-size: 24px
    color: #fff !important
    transition: transform 0.3s ease
    cursor: pointer

    &:hover
        color: #FF5522 !important

.title
    width: 100%
    position: relative
    font-size: 18px
    font-family: Poppins
    color: #4e4e4e
    text-align: left
    display: inline-block
    font-weight: 400

.body, .footer
    width: 100%
    position: relative
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 16px
    text-align: left
    font-size: 16px


.item
    align-self: stretch
    background-color: #fff
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    gap: 32px

.key
    width: 100%
    position: relative
    font-size: 16px
    display: inline-block
    font-family: 'Poppins'
    color: #216f97
    text-align: left
    font-weight: 700
    margin: 0 auto

.value
    width: 100%
    position: relative
    font-size: 16px
    font-weight: 300
    text-align: left
    margin: 0 auto

.price
    width: 100%
    font-size: 24px

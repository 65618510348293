.currency-selector
    position: relative
    display: inline-flex
    box-sizing: border-box
    flex-direction: row
    justify-content: center
    width: fit-content
    flex-wrap: wrap
    gap: 40px

    @media screen and (max-width: 768px)
        flex-direction: column
        gap: 20px
        width: 100%
        align-items: center

.label
    font-family: 'Open Sans'
    color: '#282828'
    font-weight: 400
    text-align: center
    font-size: 24px

    @media screen and (max-width: 768px)
        font-size: 18px
@import "../global/variables"

.contact-form
    width: auto
    position: relative
    display: flex
    flex-direction: column
    gap: 10px
    box-shadow: 0px 4px 18.8px rgba(1, 23, 34, 0.1)
    background-color: #fff
    border-radius: 16px
    align-items: left
    justify-content: space-between
    padding: 30px
    box-sizing: border-box
    //max-width: 600px
    max-height: 90vh
    overflow: hidden

    @media (max-width: 1280px)
        padding: 25px
        max-width: 90%

    @media (max-width: 768px)
        padding: 20px
        gap: 15px
        max-width: 100%

    @media (max-width: 480px)
        padding: 15px
        gap: 10px
        border-radius: 8px
        max-width: 100%



.form-title
    font-size: 36px
    font-weight: 400 
    font-family: 'Poppins'
    text-align: left
    color: #005581
    margin:0px

.input-row
    width: 100%
    display: flex
    gap: 20px
    box-sizing: border-box

    & > *
        flex: 1 1 auto
        min-width: 0
        max-width: 100%

    @media (min-width: 769px)
        flex-direction: row 

    @media (max-width: 768px)
        flex-direction: column
        gap: 15px

    @media (max-width: 480px)
        gap: 10px

.all-input
    display: flex
    flex-direction: column
    gap: 15px
    padding: 8px
    flex-grow: 1
    overflow-y: auto
    overflow-x: hidden
    max-height: 60vh

.action-buttons
    width: 100%
    display: flex
    flex-direction: column
    gap: 20px

    @media (max-width: 768px)
        gap: 15px

    @media (max-width: 480px)
        gap: 10px

textarea
    width: 100%
    min-height: 100px
    resize: vertical
    padding: 10px
    border: 1px solid #ccc
    border-radius: 4px
    font-family: 'Poppins', sans-serif

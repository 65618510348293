.sign-up-payment-form
    width: auto
    position: relative
    display: flex
    flex-direction: column
    gap: 20px
    border-radius: 16px
    align-items: flex-start
    box-shadow: 0px 4px 18.8px rgba(1, 23, 34, 0.1)
    background-color: #fff
    justify-content: space-between
    padding: 40px
    box-sizing: border-box
    max-height: 80vh
    overflow: hidden

    @media (max-width: 1280px)
        padding: 25px
        max-width: 90%

    @media (max-width: 768px)
        padding: 20px
        gap: 15px
        max-width: 95%

    @media (max-width: 480px)
        padding: 15px
        gap: 10px
        border-radius: 8px
        max-width: 100%

.logo-container
    display: flex
    width: 100%
    align-items: center
    justify-content: flex-start
    align-content: center

.zerone-logo
    height: 25px

    @media (max-width: 480px)
        height: 20px

.header-container
    width: auto
    position: relative
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 12px

.form-title
    font-size: 36px
    font-weight: 300 
    font-family: 'Poppins'
    text-align: left
    margin: 0
    color: #005581

.form-subtitle
    width: auto
    position: relative
    font-size: 14px
    font-family: 'Open Sans'
    color: #707070
    text-align: left
    display: inline-block

.main-wrapper
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    gap: 20px
    width: auto
    box-sizing: border-box

    @media (max-width: 768px)
        width: 100%
        flex-direction: column
        gap: 15px

.summary-section
    width: 100%
    max-width: 40vw
    position: relative
    border-radius: 8px
    background-color: #fff
    border: 1px solid #ff5522
    padding: 20px
    gap: 16px
    box-sizing: border-box
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    text-align: left

    @media (max-width: 768px)
        max-width: 100vw

.top-summary
    display: flex
    flex-direction: row
    align-items: flex-start
    justify-content: flex-start
    min-width: 100%

.left
    flex: 1
    position: relative
    display: flex
    flex-direction: row
    width: 100%

.radio-label
  display: flex
  flex: 1
  flex-direction: row
  justify-content: flex-start
  align-items: center
  cursor: pointer

.radio-input
    appearance: none
    width: 20px
    height: 20px
    border: 2px solid #FF5522
    border-radius: 50%
    margin-right: 10px
    position: relative
    cursor: pointer

    &:checked
        border-color: #FF5522

        &::after
            content: ''
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            width: 10px
            height: 10px
            background-color: #FF5522
            border-radius: 50%

.radio-text
  font-size: 18px
  font-weight: bold
  margin-left: 10px
  text-transform: capitalize

.right
    position: relative
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    font-size: 16px
    font-family: 'Poppins'

.amount
    position: relative
    font-size: 16px
    font-family: 'Poppins'
    color: #005581
    font-weight: 700
    margin: 0 0

.subscription-type
    position: relative
    font-size: 12px
    font-family: 'Open Sans'
    color: #707070
    margin: 0 0
    text-align: start

.account-description
    font-size: 14px
    color: #707070
    text-align: left

    @media (max-width: 768px)
        display: none

.change-plan
    position: relative
    font-size: 12px
    line-height: 48px
    text-decoration: underline
    font-family: 'Open Sans'
    color: #f52
    text-align: start
    display: inline-block
    cursor: pointer

.divider
    height: 100%
    width: 1px
    border-right: 1px solid #e8e8e8

    @media (max-width: 768px)
        display: none

.payment-section
    margin: 0 0
    width: 100%
    text-align: center

    @media (max-width: 768px)
        padding: 0px

.discount-section
    padding: 10px
    background-color: #f8f9fa
    box-shadow: 0 4px 66px rgba(0, 0, 0, 0.1)

.discount-table
    display: table
    margin: 0 0
    width: 100%

.table-row
    display: table-row

.table-cell
    display: table-cell
    padding: 8px 16px
    text-align: left
    font-size: 14px
    color: #333

.table-cell:first-child
    width: 50%
    font-weight: 600
    color: #555

.table-cell:nth-child(2)
    width: 50%
    text-align: end
    font-weight: 500
    color: #007bff

.message-section
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 20px

.message
    font-family: 'Poppins'
    max-width: 400px
    font-size: 16px
    font-weight: 500
    color: #0078d7
    text-align: center
    margin-top: 20px
    margin-bottom: 0
.tab-items-parent
    position: relative
    border-radius: 16px
    background-color: #fff
    border: 2px solid #ffeae4
    box-sizing: border-box
    width: fit-content
    max-width: 100%
    flex-wrap: wrap
    display: inline-flex
    justify-content: center
    padding: clamp(8px, 2vw, 16px)
    gap: clamp(10px, 2vw, 30px)
    text-align: center
    font-size: 24px
    font-family: 'Poppins'

    @media (max-width: 576px)
        width: 90%
        padding: 16px
        flex-direction: column
        justify-content: center
        min-height: auto

.tab-item
    border-radius: 8px
    background-color: #fff
    max-height: 40px
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    padding: clamp(8px, 2vw, 16px)
    font-weight: 300
    cursor: pointer
    color: #ff5522
    white-space: nowrap
    width: auto
    cursor: pointer
    transition: background-color 0.3s ease, color 0.3s ease
    font-size: clamp(16px, 2vw, 18px)

    @media (max-width: 576px)
        padding: 16px

    // &:hover
    //     background-color: #ffeae4
    //     color: #ff5522
    
    &.active
        background-color: #ff5522
        color: #fff
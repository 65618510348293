.landing-hero-container
    flex: 1
    margin: 0 auto
    position: relative
    box-sizing: border-box
    width: 100%
    max-width: 1400px
    height: auto
    overflow: hidden
    display: flex
    align-content: flex-start
    justify-content: space-between
    align-items: center
    padding: 0 40px

    @media (max-width: 1024px)
        height: auto
        flex-direction: column
        padding: 20px
        align-items: center

    @media (max-width: 768px)
        height: auto
        padding: 20px
        flex-direction: column
        text-align: center

.right-wrapper
    flex: 1
    flex-direction: column
    max-width: 40%
    position: relative
    height: 437.7px
    overflow: hidden
    width: 100%
    display: flex
    justify-content: flex-end
    align-items: center

    @media (max-width: 1024px)
        max-width: 60%
        height: auto
        justify-content: center

    @media (max-width: 768px)
        max-width: 80%
        justify-content: center
        margin-top: 20px

.play-icon
    position: absolute
    font-size: 108px
    top: 50%
    left: 50%
    z-index: 1
    transform: translate(-50%, -50%) scale(1)
    color: #FF5522
    cursor: pointer
    transition: transform 0.2s ease-in-out

    &:hover
        transform: translate(-50%, -50%) scale(1.1)

.left-wrapper
    flex: 1
    max-width: 60%
    position: relative
    flex-direction: column
    align-items: flex-start
    justify-content: center
    text-align: left
    gap: 25px

    @media (max-width: 1024px)
        display: flex
        max-width: 100%
        align-items: center
        text-align: center

    @media (max-width: 768px)
        max-width: 100%
        align-items: center
        text-align: center
    
.short-intro
    position: relative
    font-size: 24px
    font-family: 'Poppins'
    color: #fff
    text-align: left
    font-weight: 700

    @media (max-width: 768px)
        font-size: 20px
        text-align: center

.focus
    position: relative
    font-size: 64px
    font-family: 'Poppins'
    font-weight: 700
    color: #FF5522
    text-align: left

    @media (max-width: 1024px)
        font-size: 48px
        text-align: center

    @media (max-width: 768px)
        font-size: 36px
        text-align: center

.short-desc
    font-size: 24px
    font-weight: 300
    font-family: 'Open Sans'
    color: #fff
    text-align: left
    display: inline-block
    align-content: flex-start

    @media (max-width: 1024px)
        font-size: 20px
        text-align: center

    @media (max-width: 768px)
        font-size: 18px
        text-align: center

.line-break
    display: inline
    white-space: nowrap

    @media (max-width: 760px)
        display: none

.control-buttons
    width: auto
    position: relative
    gap: 15px
    margin-top: 25px
    display: flex
    flex-direction: row
    align-content: flex-start

    @media (max-width: 1024px)
        width: 100%
        justify-content: center

    @media (max-width: 768px)
        width: 100%
        flex-direction: column
        align-items: center
        gap: 10px
.zerone-bot-insight-form
    flex: 1
    margin: 0 auto
    position: relative
    border-radius: 22px
    background: linear-gradient(180.5deg, #e9f7ff, #f7fcff)
    border: 20px solid #fff
    box-sizing: border-box
    width: 100%
    padding: 40px
    max-width: 1400px
    height: auto
    overflow: hidden
    display: flex
    align-content: flex-start
    justify-content: space-between
    overflow: hidden
    gap: 20px

    @media (max-width: 1024px)
        flex-direction: column
        padding: 20px
        border: none
        gap: 30px
        border-radius: 0

    @media (max-width: 768px)
        padding: 20px
        gap: 20px
        border: none
        border-radius: 0

.right-wrapper
    width: 100%
    display: flex
    gap: 20px
    flex-direction: column
    align-content: flex-start
    justify-content: flex-start
    align-content: flex-start

    @media (max-width: 768px)
        align-items: center
        text-align: center

.text-wrapper
    width: 100%
    display: flex
    flex-direction: column
    gap: 8px
    align-items: flex-start
    justify-content: flex-start
    align-content: flex-start

    @media (max-width: 768px)
        align-items: center
        text-align: center

.highlight
    color: #FF5522

.call-for-action
    font-size: 36px
    font-weight: 400 
    font-family: 'Poppins'
    text-align: left
    color: #005581

    @media (max-width: 1024px)
        font-size: 30px
        text-align: center

    @media (max-width: 768px)
        font-size: 24px
        text-align: center

.zerone-bot-desc
    font-size: 24px
    text-align: left
    color: #707070
    font-family: 'Open Sans'

    @media (max-width: 1024px)
        font-size: 20px
        text-align: center

    @media (max-width: 768px)
        font-size: 18px
        text-align: center

.dropdown-wrapper
    margin: 50px 0px

    @media (max-width: 768px)
        width: 100%
        margin: 30px 0px

.bot-image
    flex-shrink: 0
    width: 100%
    max-width: 260px
    height: 260px
    max-height: 260px
    object-fit: contain
    align-self: center

    @media (max-width: 768px)
        max-width: 200px
        height: 200px
        max-height: 200px


@import "../global/variables"

.feature-card
    width: 100%
    display: flex
    flex-direction: column
    justify-content: space-between
    border-radius: 16px
    background-color: #fff
    border: 1px solid #d9d9d9
    box-sizing: border-box
    min-height: 650px
    overflow: hidden
    text-align: center
    font-size: 32px
    color: #1e1e1e
    font-family: Poppins
    transition: background 0.3 ease, box-shadow 0.3s ease
    overflow: hidden

    @media (min-width: 1200px)
        font-size: 32px

    @media (max-width: 768px)
        padding: 30px 0 0
        min-height: auto

    @media (max-width: 576px)
        font-size: 22px
        padding: 15px 0 0
        flex-direction: column
        justify-content: center
        min-height: auto

.hovered
    box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.08)
    background: linear-gradient(224.8deg, #fff, #d1efff)

.background-container
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 0

.background-image
    position: absolute
    top: 0
    left: 0
    margin-bottom: 0
    width: 100%
    max-width: 100%
    height: 100%
    background-size: contain
    background-repeat: no-repeat
    background-position: bottom
    opacity: 0.35
    transition: opacity 0.3s ease

.content
    display: flex
    z-index: 2
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: space-between
    padding: 50px 50px 0 50px
    gap: 28px
    overflow: hidden

    @media (max-width: 768px)
        padding: 40px 40px 0 40px
    @media (max-width: 576px)
        padding: 20px 20px 0 20px
        align-items: flex-start 

.icon-wrapper
    position: relative
    width: 100%
    max-width: 60px
    height: 60px
    padding: 10px
    aspect-ratio: 1 / 1
    display: flex
    border-radius: 8px
    justify-content: center
    align-content: center
    align-items: center
    overflow: hidden
    background-color: #F5F5F5
    border: 1px solid #707070
    transition: border-color 0.3s ease

    @media (max-width: 768px)
        width: 50px
        height: 50px

    @media (max-width: 576px)
        width: 40px
        height: 40px

.feature-icon
    width: 100%
    height: 100%
    object-fit: cover
    border-radius: 8px
    fill: none
    stroke: #707070
    transition: stroke 0.3s ease

.text-wrapper
    height: 100%
    width: 100%
    text-align: left
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 28px

    @media (max-width: 576px)
        text-align: start
        gap: 15px

.title-wrapper
    display: flex
    align-items: center
    flex-direction: row
    justify-content: flex-start
    gap: 28px
    margin-top: 0

.feature-title
    position: relative
    font-size: 24px
    font-weight: 600
    font-family: 'Poppins'
    text-align: left

    @media (min-width: 1200px)
        font-size: 32px

    @media (max-width: 768px)
        font-size: 20px

    @media (max-width: 576px)
        font-size: 18px

.special-text
    border-radius: 8px
    padding: 5px 10px
    color: #fff
    font-size: 18px
    font-family: 'Poppins'
    font-weight: 600

    @media (min-width: 1200px)
        font-size: 20px

.feature-description
    width: 100%
    font-size: 18px
    font-family: 'Open sans'
    font-style: normal
    color: #1e1e1e
    text-align: left
    display: inline-block

    @media (max-width: 768px)
        font-size: 16px

    @media (max-width: 576px)
        font-size: 14px

    @media (min-width: 1200px)
        font-size: 24px

.footer
    display: flex
    width: 100%
    max-width: 100%
    margin-bottom: 0
    flex-direction: row
    justify-content: space-between
    align-items: flex-end
    padding: 0 0 0 50px

    @media (max-width: 768px)
        padding: 0 0 0 40px
    @media (max-width: 576px)
        padding: 0 0 0 20px

.arrow-wrapper
    position: relative
    bottom: 20px
    width: 60px
    height: 60px
    border-radius: 50%
    display: flex
    border: 1px solid
    box-sizing: border-box
    justify-content: center
    align-items: center
    transition: all 0.3s ease

.arrow-icon
    transition: transform 0.3s ease

.not-hovered-arrow
    transform: rotate(45deg)


.hovered-arrow
    transform: rotate(0deg)

.feature-image
    position: relative
    margin-right: 0
    margin-bottom: 0
    width: auto
    height: auto
    max-width: 80%
    max-height: 80%
    display: flex
    justify-content: flex-end
    align-items: flex-end
    overflow: hidden
    object-fit: contain
    z-index: 1

    @media (max-width: 768px)
        max-width: 75%
        max-height: 75%

    @media (max-width: 576px)
        max-width: 75%
        max-height: 75%
        object-fit: contain

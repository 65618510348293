.account-form
  width: auto
  position: relative
  display: flex
  flex-direction: column
  max-width: 50vw
  gap: 20px
  box-shadow: 0px 4px 18.8px rgba(1, 23, 34, 0.1)
  background-color: #fff
  border-radius: 16px
  overflow-y: auto
  align-items: left
  justify-content: space-between
  padding: 30px
  box-sizing: border-box
  max-height: 90vh
  overflow: hidden

  @media (max-width: 1280px)
      padding: 25px
      max-width: 90%

  @media (max-width: 768px)
      padding: 20px
      gap: 15px
      max-width: 95%

  @media (max-width: 480px)
      padding: 15px
      gap: 10px
      border-radius: 8px
      max-width: 100%

.form-title
    font-size: 36px
    font-weight: 400 
    font-family: 'Poppins'
    text-align: left
    color: #005581
    margin:0px
    flex-shrink: 0

.billing-cycle-container
  flex-shrink: 0
  width: 100%
  display: flex
  flex-direction: column
  align-content: center
  align-items: center
  justify-content: flex-start
  font-family: 'Poppins'

.billing-note
  font-family: 'Poppins'
  font-size: 18px
  color: #333

.highlight
    width: 100%
    color: #004264
    font-size: 20px
    padding: 2px 5px
    font-weight: 600
    text-transform: uppercase

.account-row
  display: flex
  gap: 20px
  width: 100%
  box-sizing: border-box

  & > *
      flex: 1 1 auto
      min-width: 0
      max-width: 100%

  @media (min-width: 769px)
      flex-direction: row 

  @media (max-width: 768px)
      flex-direction: column


.account-options
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(45%, 1fr))
  gap: 20px
  width: 100%
  max-height: calc(90vh - 160px)
  overflow-y: auto
  padding: 10px
  box-sizing: border-box

  @media (max-width: 768px)
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr))
    gap: 15px

  & > :nth-last-child(1):nth-child(odd)
    grid-column: span 2

    @media (max-width: 768px)
      grid-column: span 1
    
.account-option
  border: 1px solid #e0e0e0
  border-radius: 8px
  padding: 15px
  background-color: #fff
  transition: all 0.3s ease
  cursor: pointer

  &:hover
    border-color: #FF5522
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1)

  &.selected
    border-color: #f52
    background-color: #fff5f0

.radio-label
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
  cursor: pointer

.radio-input
    appearance: none
    width: 20px
    height: 20px
    border: 2px solid #FF5522
    border-radius: 50%
    margin-right: 10px
    position: relative
    cursor: pointer

    &:checked
        border-color: #FF5522

        &::after
            content: ''
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            width: 10px
            height: 10px
            background-color: #FF5522
            border-radius: 50%

.radio-text
  font-size: 18px
  font-weight: bold
  margin-left: 10px
  text-transform: capitalize

.account-description
  font-size: 14px
  margin-top: 10px
  color: #707070
  text-align: left

.action-buttons
  width: 100%
  display: flex
  flex-direction: column
  gap: 20px
  flex-shrink: 0
  margin-top: auto

  @media (max-width: 768px)
    gap: 15px

  @media (max-width: 480px)
    gap: 10px
.report-category-header
    height: 100%
    flex: 1
    width: 100%
    position: relative
    display: flex
    flex-direction: row
    align-items: stretch
    justify-content: flex-start
    gap: 32px

    @media (max-width: 768px)
        flex-direction: column
        align-items: center
        gap: 16px
        padding-bottom: 20px
        border-bottom: 2px solid #ebebeb

.category-icon
    width: 100px
    position: relative
    border-radius: 50%
    height: 100px
    overflow: hidden
    flex-shrink: 0
    display: flex
    align-items: center
    justify-content: center
    padding: 5px
    box-sizing: border-box

    @media (max-width: 768px)
        width: 80px
        height: 80px

    @media (max-width: 480px)
        width: 60px
        height: 60px

.icon
    width: 100%
    height: 48px

    @media (max-width: 768px)
        height: 36px

    @media (max-width: 480px)
        height: 24px

.category-title-wrapper
    flex: 1
    width: 100%
    position: relative
    border-bottom: 2px solid #ebebeb
    box-sizing: border-box
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    gap: 8px

    @media (max-width: 768px)
        display: flex
        flex-direction: column
        border-bottom: none
        gap: 4px

.title
    height: 100%
    flex: 1
    display: flex
    align-items: center
    position: relative
    font-size: 32px
    line-height: 32px
    font-weight: 600
    font-family: 'Poppins'
    text-align: left

    @media (max-width: 768px)
        font-size: 24px
        line-height: 28px
        text-align: center

    @media (max-width: 480px)
        font-size: 18px
        line-height: 24px
        text-align: center
.toast
    display: flex
    align-items: flex-start
    justify-content: space-between
    padding: 15px 20px
    border-radius: 8px
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1)
    background-color: #fff
    font-size: 14px
    max-width: 350px
    min-width: 300px
    animation: slideIn 0.3s ease-out
    border-left: 4px solid #707070

.icon
    margin-right: 10px
    font-size: 20px
    
.content
    flex-grow: 1
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 8px

.title
    font-weight: 600
    color: #005581
    font-family: 'Poppins'

.message
    font-size: 14px
    color: #555
    display: -webkit-box
    -webkit-box-orient: vertical
    -webkit-line-clamp: 3
    overflow: hidden
    text-overflow: ellipsis
    max-width: 250px

.footer
    display: flex
    width: 100%
    flex-direction: row
    align-content: center
    justify-content: space-between

.time
    font-size: 12px
    color: #888

.link
    text-align: end
    font-size: 12px
    margin: 0 0
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    max-width: 150px

.close-button
    background: none
    border: none
    cursor: pointer
    font-size: 16px
    margin-left: 15px
    color: #ccc
    &:hover
        color: #FF5522

@keyframes slideIn
  from
    opacity: 0
    transform: translateX(100%)
  to
    opacity: 1
    transform: translateX(0)
.my-reports
    width: 100%
    position: relative
    display: grid
    gap: 64px
    align-items: stretch
    overflow: hidden 
    padding: 10px 0px
    grid-template-columns: repeat(3, minmax(200px, 1fr))

    @media screen and (max-width: 1280px)
        grid-template-columns: repeat(2, minmax(300px, 1fr))
        gap: 32px

    @media screen and (max-width: 1200px)
        grid-template-columns: repeat(3, minmax(200px, 1fr))
        gap: 32px

    @media screen and (max-width: 1024px)
        grid-template-columns: repeat(2, minmax(250px, 1fr))
        gap: 32px

    @media screen and (max-width: 992px)
        grid-template-columns: repeat(1, 1fr)
        gap: 32px

    @media screen and (max-width: 768px)
            grid-template-columns: 1fr
            gap: 20px
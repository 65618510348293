.login-form
    width: auto
    height: auto
    position: relative
    display: flex
    flex-direction: column
    gap: 20px
    box-shadow: 0px 4px 18.8px rgba(1, 23, 34, 0.1)
    background-color: #fff
    border-radius: 16px
    overflow: hidden
    align-items: center
    justify-content: space-between
    padding: 50px
    box-sizing: border-box

    @media (max-width: 1024px)
        padding: 40px

    @media (max-width: 768px)
        padding: 30px
        gap: 15px

    @media (max-width: 480px)
        padding: 20px
        gap: 10px
        border-radius: 8px

.logo-wrapper
    display: flex
    width: 100%
    align-items: center
    justify-content: center
    align-content: center

.main-wrapper
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center
    gap: 20px
    width: auto

    @media (max-width: 768px)
        width: 100%
        flex-direction: column
        gap: 15px

.logo
    height: 25px

    @media (max-width: 480px)
        height: 20px

.input-wrapper
    width: 100%
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    flex-shrink: 0
    gap: 20px

    @media (max-width: 768px)
        gap: 15px

    @media (max-width: 480px)
        gap: 10px

.action-buttons
    width: 100%
    display: flex
    flex-direction: column
    gap: 20px

    @media (max-width: 768px)
        gap: 15px

    @media (max-width: 480px)
        gap: 10px

.error
    color: #D43700
    font-size: 14px 
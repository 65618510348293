.zerone-loader
    position: relative
    width: 100%
    height: 100%
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    display: flex
    align-items: center
    justify-content: center
    z-index: 1000

.loading-text
    font-weight: 400 
    font-family: 'Poppins'
    text-align: left
    font-size: 20px
    color: #005581
    letter-spacing: 1px

@keyframes bounce
    0%
        top: 18px
        height: 5px
        border-radius: 60px 60px 20px 20px
        transform: scaleX(2)
    35%
        height: 18px
        border-radius: 50%
        transform: scaleX(1)
    100%
        top: 0
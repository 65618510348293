.title-wrapper
    position: relative
    width: 100%
    justify-content: center
    display: flex
    flex-direction: column
    align-items: center

.section-title
    width: 100%
    text-align: center
    justify-content: center
    position: relative
    font-size: 20px
    color: #707070
    letter-spacing: 0.1em
    margin-bottom: 0px //changed from 34px to 0px
    font-family: 'Open Sans'
    text-transform: uppercase

    @media (max-width: 768px)
        font-size: 18px

    @media (max-width: 576px)
        font-size: 16px

.section-subtitle
    width: 100%
    position: relative
    font-size: 36px
    line-height: 64px
    display: inline-block
    font-family: 'Poppins'
    color: #005581
    font-weight: 600
    text-align: center
    align-self: stretch
    margin-bottom: 0px //added

    @media (max-width: 768px)
        font-size: 28px
        line-height: 48px

    @media (max-width: 576px)
        font-size: 24px
        line-height: 40px
.profile-header-summary
    position: relative
    width: 100%
    max-width: 1440px
    margin: 0 auto
    box-sizing: border-box
    height: auto
    overflow: hidden
    display: flex
    flex-direction: column
    align-content: center
    justify-content: space-between
    align-items: center
    box-sizing: border-box
    background-size: cover
    background-position: top
    background-repeat: no-repeat
    padding: 60px 20px

    @media (max-width: 1024px)
        height: auto
        flex-direction: column
        padding: 40px 60px
        align-items: center

    @media (max-width: 768px)
        flex-direction: column
        padding: 40px 20px

.main-wrapper
    flex: 1
    margin: 0 auto
    position: relative
    width: 100%
    height: auto
    overflow: hidden
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    box-sizing: border-box

    @media (max-width: 1024px)
        height: auto
        flex-direction: column
        align-items: center

    @media (max-width: 768px)
        height: auto
        flex-direction: column
        text-align: center

.left-section
    flex: 1
    display: flex
    max-width: 50%
    padding: 40px
    position: relative
    flex-direction: row
    align-items: center
    text-align: left
    gap: 32px

    @media (max-width: 1024px)
        display: flex
        max-width: 100%
        align-items: center
        text-align: left

    @media (max-width: 768px)
        max-width: 100%
        width: 100%
        padding: 10px
        flex-direction: column
        align-items: center
        justify-content: center
        margin: 0 auto
        text-align: left

.right-section
    display: flex
    flex-direction: column
    gap: 16px
    padding: 40px
    max-width: 50%
    position: relative
    justify-content: center
    align-items: center
    text-align: center
    flex: 1

    @media (max-width: 1024px)
        display: flex
        max-width: 100%
        align-items: center
        text-align: center

    @media (max-width: 768px)
        max-width: 100%
        width: 100%
        padding: 10px
        flex-direction: column
        align-items: center
        justify-content: center
        margin: 0 auto
        text-align: center

.profile-details
    display: flex
    flex-direction: column
    gap: 16px
    width: 100%
    height: auto
    margin: 0
    position: relative
    align-items: flex-start
    justify-content: flex-start
    text-align: left
    flex: 1

    @media (max-width: 1024px)
        display: flex
        max-width: 100%
        align-items: flex-start
        text-align: left

    @media (max-width: 768px)
        max-width: 100%
        width: 100%
        align-items: center
        text-align: center

.profile-item
    display: flex
    flex-direction: row
    gap: 18px
    margin: 0
    width: 100%
    font-weight: 300
    position: relative
    align-items: center
    justify-content: flex-start
    font-family: 'Poppins'
    text-align: left
    color: #1e1e1e
    text-align: left
    flex: 1

    @media (max-width: 1024px)
        display: flex
        max-width: 100%
        align-items: center
        text-align: left

    @media (max-width: 768px)
        width: 100%
        display: inline-flex
        flex-direction: row
        justify-content: center
        align-items: center
        text-align: center
        gap: 0px

.name
    font-size: 24px
    font-weight: 600

.credit-balance
    color: #ff5522

.profile-picture-wrapper
    width: 204px
    height: 204px
    position: relative
    border-radius: 50%
    overflow: hidden
    object-fit: cover
    cursor: pointer
    display: block
    margin: 0 auto

    &:hover
        .camera-overlay
            z-index: 1
            display: flex
            background-color: rgba(0, 0, 0, 0.7)


.profile-picture
    width: 100%
    height: 100%
    object-fit: cover
    position: relative
    overflow: hidden
    object-fit: cover
    display: block
    margin: 0 auto

.camera-overlay
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    background-color: rgba(0, 0, 0, 0.5)
    display: none
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 8px
    color: #ffffff
    font-size: 16px
    font-weight: 700
    cursor: pointer

    @media (max-width: 1024px)
        font-size: 16px

    @media (max-width: 768px)
        font-size: 16px

.camera-icon
    width: 100%
    position: relative
    overflow: hidden
    font-size: 40px
    flex-shrink: 0
    color: #ffffff

.camera-text
    width: 100%
    position: relative
    font-size: 14px
    font-family: 'Open Sans'
    color: #ffffff
    text-align: center
    display: inline-block

.tab-section
    width: 100%
    position: relative
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 16px
    padding: 0 20px
    text-align: left
    font-size: 20px
    overflow: hidden
    color: #005581
    font-family: 'Poppins'

    @media (max-width: 1024px)
        font-size: 20px

    @media (max-width: 768px)
        font-size: 20px

.tabs
    width: 100%
    position: relative
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    gap: 64px
    text-align: left
    font-size: 20px
    color: #005581
    font-family: 'Poppins'

    @media (max-width: 1024px)
        font-size: 20px

    @media (max-width: 768px)
        gap: 32px
        font-size: 20px

.tab-button
    width: auto
    position: relative
    font-size: 20px
    z-index: 1
    font-family: 'Poppins'
    color: #005581
    font-weight: 700
    cursor: pointer
    text-align: left
    display: inline-block
    padding: 16px 0
    transition: all 0.3s ease

    &:hover
        color: #ff5522

    @media (max-width: 1024px)
        font-size: 20px

    @media (max-width: 768px)
        max-width: 100%
        text-align: center
        font-size: 16px

.active
    color: #ff5522

.active-indicator
    width: 100%
    position: absolute
    margin: 0 !important
    bottom: 0
    z-index: 1
    left: 1px
    border-radius: 3px
    background-color: #ff5522
    height: 4px

.tab-bar
    width: 100%
    border-radius: 3px
    background-color: #e8e8e8
    height: 4px
    display: flex
    padding: 1px
    box-sizing: border-box
    position: absolute
    bottom: 0
.blog-container-navigation
    width: 100%
    padding: 8px
    position: relative
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    box-sizing: border-box
    
    @media (max-width: 1280px)
        flex-direction: column
        align-items: center
        gap: 8px

.tab-items-parent
    flex-grow: 0

.search-bar
    flex-grow: 0

    @media (max-width: 1024px)
        margin-top: 8px
.dynamic-info-container
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 32px

    @media screen and (max-width: 768px)
        gap: 24px 
        padding: 12px

.info-grid
    width: 100%
    max-width: 1400px
    position: relative
    display: grid
    gap: 50px
    align-items: stretch
    overflow: hidden
    justify-items: center
    padding: 10px 0px
    grid-template-columns: repeat(3, minmax(400px, 1fr))

    @media screen and (max-width: 1280px)
        grid-template-columns: repeat(2, minmax(400px, 1fr))
        gap: 32px

    @media screen and (max-width: 1200px)
        grid-template-columns: repeat(3, minmax(200px, 1fr))
        gap: 32px

    @media screen and (max-width: 1024px)
        grid-template-columns: repeat(2, minmax(250px, 1fr))
        gap: 32px

    @media screen and (max-width: 992px)
        grid-template-columns: repeat(1, 1fr)
        gap: 32px

    @media screen and (max-width: 768px)
            grid-template-columns: 1fr
            gap: 20px

    &:has(> :only-child)
        justify-content: center
        justify-items: center
.report-category
    width: 100%
    padding: 100px 60px
    position: relative
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: space-between
    box-sizing: border-box
    gap: 20px
    font-family: 'Poppins'

    @media (max-width: 768px)
        padding: 80px 40px

    @media (max-width: 480px)
        padding: 60px 20px

.category-body
    width: 100%
    padding: 0 0 10px 132px
    display: flex
    flex-direction: column
    justify-content: flex-start
    gap: 20px

    @media (max-width: 768px)
        padding: 0

.description
    width: 100%
    font-size: 20px
    font-weight: 300
    font-family: 'Open Sans'
    color: #000
    text-align: left
    display: inline-block

    @media (max-width: 768px)
        font-size: 18px

    @media (max-width: 480px)
        font-size: 16px

.top-categories-wrapper
    width: 100%
    display: grid
    grid-template-columns: repeat(4, minmax(200px, 1fr))
    gap: 32px
    align-items: stretch
    overflow: hidden

    @media screen and (max-width: 1280px)
        grid-template-columns: repeat(2, minmax(300px, 1fr))

    @media screen and (max-width: 1200px)
        grid-template-columns: repeat(3, minmax(200px, 1fr))

    @media screen and (max-width: 1024px)
        grid-template-columns: repeat(2, minmax(250px, 1fr))

    @media screen and (max-width: 992px)
        grid-template-columns: repeat(1, 1fr)

    @media screen and (max-width: 768px)
            grid-template-columns: 1fr
            gap: 20px
.interactive-chart-container
    width: 100%
    display: flex
    flex-direction: column
    gap: 10px
    align-items: center

    @media (max-width: 768px)
        max-width: 100%

.chart-title
    font-size: 1.2rem
    font-weight: 600
    color: #005581
    text-align: center
    font-family: 'Poppins', sans-serif

    @media (max-width: 768px)
        font-size: 1rem

    @media (max-width: 480px)
        font-size: 0.9rem

.chart-wrapper
    width: 100%
    display: flex
    flex-direction: column
    gap: 10px
    align-items: center

    @media (max-width: 768px)
        max-width: 100%

.chart-annotation
    font-size: 14px
    font-weight: 400
    color: #707070
    text-align: center
    font-family: 'Poppins', sans-serif

    @media (max-width: 768px)
        font-size: 12px

    @media (max-width: 480px)
        font-size: 12px
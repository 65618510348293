.header-section
    height: 100%
    min-height: 100vh
    width: 100%
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    gap: 8px
    text-align: center
    background: transparent

    @media (max-width: 1024px)
        height: auto

.navigation-wrapper
    width: 100%

.main-wrapper
    width: 100%
    height: 100%
    min-height: 100vh
    background: linear-gradient(180deg, #ECF9FF 0%, #ECF9FF 100%)
    overflow: hidden

    @media (max-width: 1024px)
        height: auto
        padding-bottom: 20px

.background-container
    width: 100%
    height: 100%
    min-height: 80vh
    gap: 32px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 64px 0px 20px 0px
    z-index: 0
    background: url('/assets/images/svg/main-graph.svg') no-repeat bottom
    background-size: cover

    @media (max-width: 1024px)
        padding: 20px 0px
        height: auto
        background-size: cover

.hero-description
    max-width: 752px
    width: 100%
    font-family: 'Open Sans'
    font-size: 20px
    color: #707070
    font-weight: 400
    line-height: 27.24px
    text-align: center
    text-underline-position: from-font
    text-decoration-skip-ink: none

    @media screen and (max-width: 768px)
        font-size: 16px
        line-height: 1.5
        padding: 0 16px

.mission-vision
    max-width: 1000px
    width: 100%
    font-family: 'Open Sans'
    font-size: 20px
    color: #282828
    font-size: 20px
    font-weight: 400
    line-height: 27.24px
    text-align: center
    text-underline-position: from-font
    text-decoration-skip-ink: none

    @media screen and (max-width: 768px)
        font-size: 16px
        line-height: 1.5
        padding: 0 16px

.highlight
    color: #005581
    font-family: Open Sans
    font-size: 20px
    font-weight: 400
    line-height: 27.24px
    text-align: center
    text-underline-position: from-font
    text-decoration-skip-ink: none

    @media screen and (max-width: 768px)
        font-size: 16px
        line-height: 1.5
        padding: 0 16px
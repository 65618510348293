@import "../global/variables"

.sign-up-form
    width: auto
    max-width: 50vw
    position: relative
    display: flex
    flex-direction: column
    gap: 10px
    box-shadow: 0px 4px 18.8px rgba(1, 23, 34, 0.1)
    background-color: #fff
    border-radius: 16px
    align-items: left
    justify-content: space-between
    padding: 20px
    box-sizing: border-box
    max-height: 80vh
    overflow: hidden

    @media (max-width: 1280px)
        padding: 5px
        max-width: 90%

    @media (max-width: 768px)
        padding: 20px
        gap: 15px
        max-width: 95%

    @media (max-width: 480px)
        padding: 15px
        gap: 10px
        border-radius: 8px
        max-width: 100%
.logo-wrapper
    display: flex
    width: 100%
    align-items: center
    justify-content: center
    align-content: center

.form-title
    font-size: 36px
    font-weight: 400 
    font-family: 'Poppins'
    text-align: left
    color: #005581
    margin:0px

.logo-container
    display: flex
    width: 100%
    align-items: center
    justify-content: center
    align-content: center

.zerone-logo
    height: 25px

    @media (max-width: 480px)
        height: 20px

.input-row
    width: 100%
    display: flex
    gap: 20px
    box-sizing: border-box

    & > *
        flex: 1 1 auto
        min-width: 0
        max-width: 100%

    @media (min-width: 769px)
        flex-direction: row 

    @media (max-width: 768px)
        flex-direction: column
        gap: 15px

    @media (max-width: 480px)
        gap: 10px

.privacy-note
    font-size: 14px
    color: #1E1E1E
    margin: 0px

    @media (max-width: 576px)
        font-size: 12px

.privacy-link
    text-decoration: none

    &:hover
        text-decoration: underline

.action-buttons
    width: 100%
    display: flex
    flex-direction: column
    gap: 20px

    @media (max-width: 768px)
        gap: 15px

    @media (max-width: 480px)
        gap: 10px

.input-container
    position: relative
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 20px
    width: 100%
    


.label
    color: #004264
    text-align: start
    font-size: 16px
    font-family: 'Poppins'
    font-weight: 500

    @media (max-width: 768px)
        font-size: 14px

    @media (max-width: 480px)
        font-size: 12px

.required
    color: #FF5522

.radio-group
    display: flex
    flex-direction: row
    gap: 10px
    width: 100%

.radio-label
    display: flex
    align-items: center
    cursor: pointer

.radio-input
    appearance: none
    width: 20px
    height: 20px
    border: 2px solid #FF5522
    border-radius: 50%
    margin-right: 10px
    position: relative
    cursor: pointer

    &:checked
        border-color: #FF5522

        &::after
            content: ''
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            width: 10px
            height: 10px
            background-color: #FF5522
            border-radius: 50%

.radio-text
    color: #004264
    font-size: 16px
    font-family: 'Poppins'
    font-weight: 500

    @media (max-width: 768px)
        font-size: 14px

    @media (max-width: 480px)
        font-size: 12px

.privacy-link
    color: #005581


.all-input
    display: flex
    flex-direction: column
    gap: 15px
    padding: 8px
    flex-grow: 1
    overflow-y: auto
    overflow-x: hidden
    max-height: 60vh

.error
    color: #D43700
    font-size: 14px 
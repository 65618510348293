.individual-business-containeer
    width: 90%
    display: flex
    flex-direction: column
    align-items: center
    align-content: center
    justify-content: center
    overflow: hidden
    gap: 40px
    padding: 20px
    margin: 0 auto

    @media (max-width: 1440px)
            width: 80%

    @media (max-width: 1024px)
        width: 100%
        padding: 20px 

    @media (max-width: 768px)
            width: 100%

    @media (max-width: 576px)
        width: 100%
        padding: 16px
        flex-direction: column
        justify-content: center
        min-height: auto

.price-plan-grid
    display: grid
    margin-top: 40px
    width: 100%
    grid-template-columns: repeat(4, minmax(250px, 1fr))
    gap: 40px
    justify-content: center
    align-content: center
    align-items: center

    @media screen and (max-width: 1280px)
        grid-template-columns: repeat(2, minmax(300px, 1fr))

    @media screen and (max-width: 1200px)
        grid-template-columns: repeat(3, minmax(200px, 1fr))

    @media screen and (max-width: 1024px)
        grid-template-columns: repeat(2, minmax(250px, 1fr))
        gap: 30px

    @media screen and (max-width: 992px)
        grid-template-columns: repeat(2, minmax(200px, 1fr))

    @media screen and (max-width: 768px)
            grid-template-columns: 1fr
            gap: 20px
.footer-main
    width: 100%
    background-color: #def4ff
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    padding: 200px 180px 80px 180px
    box-sizing: border-box
    gap: 60px
    font-family: 'Poppins', sans-serif
    font-size: 16px
    color: #4e4e4e

    @media (max-width: 1024px)
        padding: 100px 80px 60px 80px

    @media (max-width: 768px)
        padding: 80px 40px 40px 40px


.title-section
    width: 100%
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    min-width: 240px
    text-align: left
    font-size: 16.18px
    font-family: 'Poppins'
    
    @media (max-width: 600px)
            gap: 12px 
            flex-direction: column
            align-items: flex-start
            justify-content: flex-start

    .logo
        width: 175px
        position: relative
        cursor: pointer
        height: 50px

    .fuelling-dev-wrapper
        align-self: stretch
        border-radius: 16px
        background-color: #fff
        display: flex
        flex-direction: row
        align-items: center
        justify-content: center
        padding: 16px
        color: #707070

        @media (max-width: 600px)
            font-size: 14px
            padding: 8px
        p
            margin: 0

.text-links-section
    width: 100%
    position: relative
    display: flex
    flex-direction: row
    justify-content: space-between

    @media (max-width: 1024px)
        gap: 40px

    @media (max-width: 600px)
        flex-direction: column
        gap: 30px


    .text-links-item
        width: auto
        position: relative
        display: flex
        flex-direction: column
        align-items: flex-start
        justify-content: flex-start
        gap: 12px
        text-align: left
        font-size: 16px
        color: #005581
        font-family: 'Inter'

        .item-title
            position: relative
            font-size: 20px
            line-height: 140%
            font-weight: 600
            font-family: 'Poppins'
            color: #005581
            text-align: left

            @media (max-width: 768px)
                font-size: 18px

        .link
            position: relative
            font-size: 16px
            font-family: 'Inter'
            color: #005581
            text-align: left
            display: inline-block

            &:hover
                transform: scale(1.05)

            @media (max-width: 768px)
                font-size: 14px

.copyright-socials-section
    width: 100%
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between

    @media (max-width: 600px)
        flex-direction: column
        align-items: flex-start
        gap: 20px

    .copyright
        text-align: left
        font-size: 16px
        color: #4e4e4e
        font-family: 'Open Sans'
        display: inline-block

    .socials
        display: flex
        flex-direction: row
        align-items: flex-start
        gap: 16px

        @media (max-width: 768px)
            gap: 12px

    .social-icon
        width: 24px
        height: 24px
        background-size: contain
        background-repeat: no-repeat
        background-position: center
        filter: invert(35%) sepia(83%) saturate(4563%) hue-rotate(354deg) brightness(103%) contrast(109%)

        &:hover
            transform: scale(1.05)

        a
            display: block
            width: 100%
            height: 100%
        



    
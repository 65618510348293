.report-summary-body
    flex: 1
    margin: 0 auto
    position: relative
    width: 100%
    height: 100vh
    overflow: hidden
    display: flex
    flex-direction: row
    align-content: flex-start
    justify-content: space-between
    align-items: center
    box-sizing: border-box
    padding: 60px 100px

    @media (max-width: 1024px)
        height: auto
        flex-direction: column
        padding: 40px 60px
        align-items: center

    @media (max-width: 768px)
        height: auto
        flex-direction: column
        padding: 40px 20px

.toc
    display: flex
    flex-direction: column
    gap: 16px
    width: 100%
    position: relative
    align-items: flex-start
    justify-content: flex-start
    text-align: left
    flex: 1

    @media (max-width: 1024px)
        display: flex
        max-width: 100%
        align-items: flex-start
        text-align: left

    @media (max-width: 768px)
        max-width: 100%
        align-items: flex-start
        text-align: left

.showcase
    display: flex
    flex-direction: column
    gap: 16px
    max-width: 50%
    position: relative
    justify-content: center
    align-items: center
    text-align: center
    flex: 1

    @media (max-width: 1024px)
        display: flex
        max-width: 100%
        align-items: center
        text-align: center

    @media (max-width: 768px)
        max-width: 100%
        align-items: center
        text-align: center

.title
    width: 100%
    font-size: 24px
    font-weight: 300
    color: #005581
    margin: 0
    padding: 0
    text-align: left
    padding: 16px 0
    border-bottom: 1px solid #e3e3e3
    font-family: 'Poppins'
    line-height: 1.5

    @media (max-width: 1024px)
        font-size: 24px

    @media (max-width: 768px)
        font-size: 24px

.toc-list
    width: 100%
    position: relative
    display: flex
    flex-direction: column
    display: inline-block
    font-size: 18px

    @media (max-width: 1024px)
        font-size: 16px

.toc-item
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    width: 100%
    gap: 4px
    position: relative
    font-size: 16px
    font-family: 'Open Sans'
    color: #707070
    font-weight: 400
    text-align: left
    cursor: pointer

    @media (max-width: 1024px)
        font-size: 14px

    @media (max-width: 768px)
        font-size: 14px

.toc-item-heading
    position: relative
    font-size: 16px
    font-family: 'Poppins'
    color: #005581
    font-weight: 400
    text-align: left
    padding: 2px 0
    cursor: pointer

    @media (max-width: 1024px)
        font-size: 14px

    @media (max-width: 768px)
        font-size: 14px

.toc-item-subheading
    color: #707070
    font-size: inherit
    padding-left: 27px
    gap: 4px

.showcase-content
    display: flex
    flex-direction: column
    gap: 16px
    width: 100%
    position: relative
    justify-content: center
    align-items: center
    font-size: 16px
    font-family: 'Open Sans'
    color: #707070
    background: rgba(255, 255, 255, 0.8)
    font-weight: 400
    text-align: left

    @media (max-width: 1024px)
        font-size: 14px

    @media (max-width: 768px)
        font-size: 14px

.blurb    
    width: 100%
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    font-size: 16px
    font-family: 'Open Sans'
    color: #707070
    background: rgba(255, 255, 255, 0.8)
    font-weight: 400
    text-align: left
    filter: blur(2px)

    @media (max-width: 1024px)
        font-size: 14px

    @media (max-width: 768px)
        font-size: 14px

.showcase-note
    color: #005581
    background: rgba(255, 255, 255, 0.8)
    z-index: 1000
    position: absolute
    font-family: 'Poppins'
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 100%
.education-container
    width: 90%
    flex: 1
    position: relative
    border-radius: 8px
    background-color: #fff
    overflow: hidden
    flex-shrink: 0
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    padding: 64px
    box-sizing: border-box
    text-align: left
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1)
    margin: 0 auto

    @media screen and (max-width: 1440px)
            width: 80% 

    @media screen and (max-width: 768px)
            width: 95%

    @media screen and (max-width: 768px)
        width: 95%
        flex-direction: column
        align-items: flex-start
        padding: 32px

.left-wrapper
    position: relative
    display: flex
    flex-direction: column
    align-content: flex-start
    justify-content: flex-start

    @media screen and (max-width: 768px)
        align-items: center
        text-align: center

.right-wrapper
    position: relative
    display: flex
    flex-direction: column
    align-content: flex-start

    @media (max-width: 768px)
        padding: 32px 0
        align-items: center
        text-align: center

.heading
    font-size: 36px
    font-family: 'Open Sans'
    color: #005581
    font-weight: 700

    @media screen and (max-width: 768px)
        font-size: 28px

.feature-list
    position: relative
    width: 100%
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: 16px
    padding: 36px 0

    @media screen and (max-width: 768px)
        align-items: center
        padding: 20px 0

.item
    position: relative
    width: 100%
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    gap: 24px
    text-align: left
    font-family: 'Open Sans'
    color: #000000
    font-size: 16px

    @media screen and (max-width: 768px)
        justify-content: center 
        gap: 16px

.check-icon
    overflow: hidden
    color: #005581
    width: 24px
    height: 24px
    flex-shrink: 0

    
.profile-body-section
    position: relative
    display: flex
    flex-direction: column
    width: 100%
    max-width: 1440px
    margin: 0 auto
    height: auto
    overflow: hidden
    align-content: flex-start
    justify-content: space-between
    align-items: flex-start
    box-sizing: border-box
    padding: 0px 20px 60px 20px

    @media (max-width: 1024px)
        height: auto
        flex-direction: column
        align-items: center
        padding: 40px 60px

    @media (max-width: 768px)
        flex-direction: column
        padding: 40px 20px

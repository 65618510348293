.workSpacePage
  min-height: 100vh
  width: 100%
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  position: relative
  display: flex
  flex-direction: column


.mainContent
  flex: 1
  display: flex
  justify-content: space-evenly
  align-items: center
  padding: 20px
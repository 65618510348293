.payment-form
    width: 100%
    min-width: 30vw
    margin: 0 auto
    box-sizing: border-box
    text-align: center

    @media (max-width: 768px)
        box-shadow: none
        background-color: transparent

.message
    font-size: 18px
    font-weight: 600
    font-family: 'Poppins', sans-serif
    color: #2b2b2b
    margin: 0
    padding: 0
    text-align: center
    margin-bottom: 20px

    @media (max-width: 768px)
        font-size: 16px
        margin-bottom: 15px
        line-height: 1.4

.total-amount
    font-size: 18px
    font-weight: 500
    font-family: 'Poppins', sans-serif
    margin-bottom: 20px
    color: #005581

    span
        font-weight: 700
        color: #2b2b2b

    @media (max-width: 768px)
        font-size: 16px
        margin-bottom: 15px
        line-height: 1.4

.card-element-container
    width: 100%
    background-color: #f9f9f9
    border: 1px solid #e8e8e8
    border-radius: 8px
    padding: 15px
    box-sizing: border-box
    margin-bottom: 20px

.submit-button, .free-plan-button
    width: 100%
    position: relative
    display: inline-block
    min-height: 48px
    border-radius: 5px
    overflow: hidden
    align-items: center
    justify-content: center
    padding: 0px 20px
    box-sizing: border-box
    gap: 8px
    font-weight: 600
    background-color: #FF5522
    color: #FFFFFF
    text-align: center
    font-size: 18px
    font-family: 'Poppins'
    transition: all 0.3s ease
    cursor: pointer
    line-height: 1.5
    letter-spacing: 0.5px

    &[data-coming-btn] 
        min-width: fit-content
        padding: 8px 16px

    .span
        font-size: 20px

    &:hover
        background-color: #FFFFFF
        color: #FF5522
        box-shadow: 4px 4px 0px #FF5522

    &:disabled
        background-color: #b0b0b0
        cursor: not-allowed

.free-plan-option
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    margin-top: 20px
    text-align: center

.free-plan-button
    background-color: #f0f0f0
    color: #6c757d

    &:hover
        background-color: #6c757d
        color: #f0f0f0
        box-shadow: 4px 4px 0px #f0f0f0

    &:disabled
        background-color: #b0b0b0
        cursor: not-allowed

.payment-message
    text-align: center
    font-size: 14px
    max-width: 300px
    color: #6c757d

.loader
    align-content: center
    justify-content: center

@import "../global/variables"

.contact-sales-section
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    gap: 64px
    align-items: center
    padding: 100px 60px

    @media (max-width: 768px)
        padding: 80px 20px

    @media (max-width: 576px)
        padding: 60px 15px

.title-wrapper
    position: relative
    width: 100%
    justify-content: center
    display: flex
    flex-direction: column
    align-items: center

.section-title
    width: 100%
    text-align: center
    justify-content: center
    position: relative
    font-size: 20px
    color: #707070
    letter-spacing: 0.1em
    margin-bottom: 40px
    font-family: $za-font-family-open-sans
    text-transform: uppercase

    @media (max-width: 768px)
        font-size: 18px

    @media (max-width: 576px)
        font-size: 16px

.section-subtitle
    width: 100%
    position: relative
    font-size: 52px
    line-height: 64px
    display: inline-block
    font-family: $za-font-family-poppins
    color: #005581
    font-weight: 600
    text-align: center
    align-self: stretch

    @media (max-width: 768px)
        font-size: 28px
        line-height: 48px

    @media (max-width: 576px)
        font-size: 24px
        line-height: 40px

.report-categories-header-section
    height: 100%
    min-height: 100vh
    width: 100%
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    gap: 8px
    text-align: center
    background: transparent

    @media (max-width: 1024px)
        height: auto

.navigation-wrapper
    width: 100%

.main-wrapper
    width: 100%
    height: 100%
    min-height: 100vh
    background: linear-gradient(180deg, #ECF9FF 0%, #ECF9FF 100%)
    overflow: hidden

    @media (max-width: 1024px)
        height: auto
        padding-bottom: 20px

.background-container
    width: 100%
    height: 100%
    min-height: 80vh
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding-bottom: 20px
    z-index: 0
    background: url('/assets/images/svg/main-graph.svg') no-repeat bottom
    background-size: cover

    @media (max-width: 1024px)
        padding-bottom: 20px
        height: auto
        background-size: cover
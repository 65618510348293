.about-main-section
    position: relative
    height: 100%
    min-height: 100vh
    width: 100%
    height: auto
    overflow: hidden
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    padding: 60px 100px
    box-sizing: border-box
    gap: 64px

    @media (max-width: 1024px)
        padding: 40px 60px

    @media (max-width: 768px)
        padding: 40px 20px
.successSection
  width: auto
  height: auto
  max-width: 50%
  position: relative
  padding: 50px
  display: flex
  flex-direction: column
  align-items: center
  gap: 20px
  border-radius: 16px
  opacity: 1
  background-color: #FFFFFF
  margin: 0 auto 

  @media (max-width: 768px)
    padding: 30px
    gap: 15px
    max-width: 80%

  @media (max-width: 480px)
    padding: 20px
    gap: 10px
    border-radius: 8px
    width: 80%

.animation
  width: 50%
  height: auto

  @media (max-width: 480px)
    max-width: 150px

.title
  font-family: 'Poppins', sans-serif
  font-size: 22px
  font-weight: 600
  line-height: 36px
  text-align: center
  color: #005581
  margin: 0

  @media (max-width: 768px)
    font-size: 22px
    line-height: 33px

  @media (max-width: 480px)
    font-size: 20px
    line-height: 30px

.subtitle
  font-family: 'Poppins', sans-serif
  font-size: 18px
  font-weight: 600
  line-height: 36px
  text-align: center
  color: #707070
  margin: 0

  @media (max-width: 768px)
    font-size: 20px
    line-height: 30px

  @media (max-width: 480px)
    font-size: 18px
    line-height: 27px

.redirect
  display: flex
  flex-direction: column
  align-items: center
  gap: 4px

.note, .redirect
  font-family: 'Open Sans', sans-serif
  font-size: 12px
  font-weight: 300
  line-height: 19.07px
  text-align: center
  color: #6c757d
  margin: 0

  @media (max-width: 480px)
    font-size: 11px
    line-height: 16px

.redirect
  color: #0f8dcc
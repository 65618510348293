.report-page
    width: 100%
    flex-direction: column
    align-content: center
    justify-content: center
    align-items: center
    overflow: hidden

.overlay
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.5)
    z-index: 1000
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    gap: 16px
    padding: 16px
    box-sizing: border-box
    letter-spacing: 1px
    //text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5)

    @media (max-width: 768px)
        font-size: 20px
        padding: 8px

.loader
    width: auto
    height: auto
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

    @media (max-width: 768px)
        width: 100%
        height: 100%
        top: 0
        left: 0
        transform: none
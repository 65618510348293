@import "../global/variables"

.contact-us-section
    padding: clamp(40px, 5vw, 80px) clamp(10px, 5%, 50px)
    display: flex
    width: 100%
    max-width: 1200px
    height: auto
    aspect-ratio: 2.5 / 1
    margin: 0 auto
    align-content: center
    flex-direction: column
    justify-content: center
    align-items: center
    overflow: hidden
    box-sizing: border-box
    background-color: transparent
    background-image: url('/assets/images/svg/contact-frame-bg.svg')
    background-size: cover
    background-repeat: no-repeat
    background-position: top
    text-align: center
    font-size: 24px
    border-radius: 32px
    font-family: $za-font-family-open-sans

    @media (max-width: 1024px)
        padding: 40px 30px
        font-size: 22px
        border-radius: 0
        aspect-ratio: 1.5 / 1

    @media (max-width: 820px)
        padding: 20px 10px
        font-size: 18px
        border-radius: 0
        aspect-ratio: auto

.title
    width: 100%
    text-align: center
    justify-content: center
    position: relative
    font-size: 20px
    color: #707070
    letter-spacing: 0.1em
    margin-bottom: 34px
    font-family: 'Open Sans'
    text-transform: uppercase

    @media (max-width: 768px)
        font-size: 18px

    @media (max-width: 576px)
        font-size: 16px

.subtitle
    width: 100%
    position: relative
    font-size: 36px
    line-height: 64px
    display: inline-block
    font-family: 'Poppins'
    color: #FFFFFF
    font-weight: 600
    text-align: center
    align-self: stretch
    margin: 0px

    @media (max-width: 768px)
        font-size: 28px
        line-height: 48px

    @media (max-width: 576px)
        font-size: 24px
        line-height: 40px
.heading-container
    width: 100%
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    padding-inline: 5%
    gap: 24px
    padding-bottom: 15px

.contact-container
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    padding-inline: 5%
    gap: 150px

    @media (max-width: 1024px)
        flex-direction: column
        gap: 60px

    @media (max-width: 768px)
        gap: 30px

    @media (min-width: 600px) and (max-width: 1024px)
            flex-direction: row
            gap: 100px

.item
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: clamp(3px, 3vw, 10px)
    cursor: pointer
    max-width: 250px
    transition: transform 0.3s ease, color 0.3s ease, background-color 0.3s ease

    &:hover
        transform: scale(1.05)

    img.icon
        width: clamp(80px, 10vw, 120px)
        height: clamp(80px, 10vw, 120px)
        position: relative
        overflow: hidden
        flex-shrink: 0
        object-fit: cover

        @media (max-width: 1024px)
            width: clamp(80px, 12vw, 120px)
            height: clamp(80px, 12vw, 120px)

        @media (max-width: 768px)
            width: clamp(60px, 15vw, 100px)
            height: clamp(60px, 15vw, 100px)

    p
        color: #FFFFFF
        text-align: center
        leading-trim: both
        text-edge: cap
        font-size: 24px
        font-style: normal
        font-weight: 700
        line-height: normal

        @media (max-width: 1024px)
            font-size: 22px

        @media (max-width: 768px)
            font-size: 18px

    a
        color: #B6FBFF
        font-size: 24px
        font-weight: 400
        text-decoration: none

        @media (max-width: 1024px)
            font-size: 24px

        @media (max-width: 768px)
            font-size: 18px
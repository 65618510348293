@import "../global/variables"

.testimonials-section
    position: relative
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 100%
    overflow: hidden
    height: 100%
    background-image: url('../../assets/images/png/dot-grid.png') 
    background-size: cover 
    background-position: center
    gap: 48px
    padding: 80px

    @media (max-width: 768px)
        padding: 20px

.testimonial-container
    display: flex
    flex-direction: column
    align-items: center
    transition: transform 0.5s ease-in-out

.testimonial-info
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    opacity: 1
    transition: transform 0.5s ease-in-out
    gap: 48px
    justify-content: space-between
    color: #1E1E1E

.section-title
    width: 100%
    text-align: center
    justify-content: center
    position: relative
    font-size: 20px
    color: #707070
    letter-spacing: 0.1em
    margin-bottom: 40px
    font-family: $za-font-family-open-sans
    text-transform: uppercase

.section-subtitle
    width: 100%
    position: relative
    font-size: 36px
    line-height: 64px
    display: inline-block
    font-family: $za-font-family-poppins
    color: #005581
    font-weight: 700
    text-align: center
    align-self: stretch

.testimonial-name
    font-weight: 600
    font-size: 24px
    font-family: 'Poppins'
    line-height: normal
    text-align: left
    color: #1E1E1E

.testimonial-title
    font-size: 24px
    line-height: normal
    font-weight: 500
    font-family: Poppins
    color: #707070

.testimonee-info
    padding: 0px 0px 0px 48px


.testimonial-name,
.testimonial-title
    margin-top: 5px

.testimonial-img 
    border-radius: 50%
    width: 100px
    height: 100px
    object-fit: cover

.testimonial-statement-wrapper
    width: 90%
    position: relative
    align-content: center
    display: flex
    flex-direction: row
    gap: 48px
    align-items: center
    justify-content: space-between

.testimonial-statement
    flex: 1
    width: 100%
    position: relative
    font-size: 32px
    line-height: 64px
    font-weight: 600
    font-family: Poppins
    text-align: center
    display: inline-block
    color: #5E5D5D

.testimonee
    display: flex
    align-items: center
    justify-content: center 
    margin-bottom: 20px 
    width: 100% 

.opening-quote,
.closing-quote 
   width:auto
   height:auto 

@media (max-width: 768px)
   .testimonial-container,
   .testimonee,
   .testimonial-statement-wrapper 
       flex-direction: column 

   .testimonial-img 
       margin-bottom: 10px 

   .section-title,
   .section-subtitle 
       font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (768 - 320))) 

   .opening-quote,
   .closing-quote 
       max-width:auto
       max-height:auto

@media (max-width: 576px)
   .section-title,
   .section-subtitle 
       font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (576 - 320)))

   .opening-quote,
   .closing-quote 
       max-width:auto
       max-height:auto
.invoices
    display: flex
    flex-direction: column
    gap: 24px

.invoice-table
    display: flex
    flex-direction: column
    gap: 12px
    margin-top: 16px
    width: 100%

    @media (max-width: 768px)
        gap: 8px

.invoices-table-header
    width: 100%
    position: relative
    display: flex
    flex-direction: row
    font-size: 24px
    font-family: 'Open Sans'
    color: #1e1e1e
    text-align: left
    font-weight: 300

    @media (max-width: 768px)
        font-size: 20px

.invoices-table-body
    display: flex
    flex-direction: column
    gap: 12px
    width: 100%

.invoices-table-row
    display: flex
    flex-direction: row
    gap: 12px
    width: 100%
    padding: 16px 0
    border-bottom: 1px solid #e0e0e0

.invoices-table-header-cell, .invoices-table-body-cell
    font-weight: 600
    width: 100%
    display: flex
    flex-direction: row
    gap: 12px
    text-align: left
    background-color: transparent !important

    @media (max-width: 768px)

        &:nth-child(2)
            display: none

.invoices-table-header-cell
    display: inline-block
    font-size: 24px !important
    text-transform: none !important
    font-weight: 300 !important
    font-family: 'Open Sans' !important
    color: #1e1e1e !important

    @media (max-width: 768px)
        font-size: 18px !important
            

    &:last-child
        margin: 0 auto
        text-align: center !important

.invoices-table-body-cell
    font-size: 16px !important
    font-weight: 500 !important
    color: #1e1e1e !important
    font-family: 'Open Sans' !important
    text-transform: none !important
    width: 100% !important

.download-link
    font-size: 24px
    cursor: pointer
    width: 100%
    display: flex
    flex-direction: row
    justify-content: center
    align-items: flex-start
    color: #1e1e1e !important
    
    &:hover
        color: #FF5522 !important
        transition: color 0.2s
        transform: scale(1.1)

    &:hover>.download-icon
        color: #FF5522 !important
        transition: color 0.2s
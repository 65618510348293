@import "../global/variables"

.compare-plans-section
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  background: transparent
  padding: 100px 60px
  gap: 64px

  @media (max-width: 992px)
    display: none

.note
  width: 100%
  text-align: center
  font-weight: 300
  color: #282828
  padding: 0px 0px 0px 60px

  @media (max-width: 1024px)
    padding: 0px


.dataPrivacySection
  background-color: #FFFFFF
  padding: 40px
  max-width: 1200px
  margin: 0 auto
  text-align: left

.header
  margin-bottom: 40px

.title
  color: #005581
  font-family: 'Poppins', sans-serif
  font-size: 52px
  font-weight: 600
  margin-bottom: 10px

.lastModified
  color: #707070
  font-family: 'Open Sans', sans-serif
  font-size: 16px
  line-height: 21.79px
  text-align: left

.content
  font-family: 'Open Sans', sans-serif
  color: #333333
  text-align: justify

.subtitle
  color: #005581
  font-family: 'Poppins', sans-serif
  font-size: 24px
  font-weight: 600
  margin-top: 30px
  margin-bottom: 20px

.section
  margin-bottom: 20px

.sectionContent
  padding-left: 20px

p
  margin-bottom: 15px
  line-height: 1.6

ul
  margin-bottom: 15px
  padding-left: 40px

  li
    margin-bottom: 10px

h3
  color: #005581
  font-family: 'Poppins', sans-serif
  font-size: 20px
  font-weight: 600
  margin-top: 20px
  margin-bottom: 10px

.officeInfo
  margin-bottom: 20px

  h4
    font-size: 18px
    color: #333
    margin: 5px

  address
    font-style: normal
    line-height: 1.5
    padding-left:10px

.contactDetails
  margin-top: 20px

.link
  color: #005581
  text-decoration: none
  &:hover
    text-decoration: underline

.thankYouNote
  margin-top: 30px
  font-style: italic
  text-align: center

@media (max-width: 768px)
  .dataPrivacySection
    padding: 30px

  .title
    font-size: 40px

  .lastModified
    font-size: 14px

  .subtitle
    font-size: 20px

@media (max-width: 480px)
  .dataPrivacySection
    padding: 20px

  .title
    font-size: 32px

  .lastModified
    font-size: 12px

  .subtitle
    font-size: 18px
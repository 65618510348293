.account-details
    position: relative
    width: 100%
    display: flex
    flex-direction: column
    gap: 40px
    padding-bottom: 40px
    font-family: 'Poppins'

    @media (max-width: 1024px)
        max-width: 100%
        align-items: flex-start

    @media (max-width: 768px)
        gap: 30px
        padding-bottom: 30px

.section
    display: flex
    flex-direction: column
    align-content: flex-start
    justify-content: flex-start
    min-height: 40vh
    gap: 24px
    width: 100%

.section-label
    font-size: 24px
    font-weight: 600
    color: #1e1e1e
    width: 100%
    text-align: left
    border-bottom: 1px solid #e0e0e0
    padding-bottom: 12px

    @media (max-width: 768px)
        font-size: 20px

.section-content
    display: flex
    flex-direction: column
    gap: 20px
    width: 100%
    
    @media (max-width: 768px)
        gap: 16px

.section-subtitle, .section-text
    position: relative
    font-size: 18px
    font-weight: 300
    color: #1e1e1e
    width: 100%
    text-align: left
    display: inline-block

    @media (max-width: 768px)
        font-size: 16px

.section-subtitle
    font-size: 24px
    margin: 0 0

    @media (max-width: 768px)
        font-size: 20px

.section-text
    color: #707070
    font-weight: 400
    font-size: 16px
    line-height: 1.5
    display: swap
    font-family: 'Poppins'

.subscription-description
    max-width: 60%

    @media (max-width: 768px)
        max-width: 100%

.field-group
    display: flex
    flex-direction: column
    gap: 12px
    width: 100%

.field-label
    color: #707070
    font-size: 16px
    font-family: 'Open Sans'
    font-weight: 300

.field-value
    font-size: 18px
    font-weight: 500
    color: #1e1e1e

.payment-card
    background: #f5f5f5
    border-radius: 8px
    padding: 16px
    display: flex
    flex-direction: column
    gap: 8px
    margin: 12px 0

.card-brand
    font-weight: 600
    text-transform: capitalize

.card-number
    letter-spacing: 1px

.card-expiry
    color: #707070
    font-size: 14px

.support-text
    color: #707070
    font-family: 'Open Sans'
    width: 100%
    text-align: left
    font-size: 16px
    line-height: 1.5
    margin: 16px 0

.contact-link
    color:#FF5522
    text-decoration: none
.catalog-card
    width: 100%
    position: relative
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    min-width: 350px
    gap: 16px
    background: #fff
    border-radius: 10px
    overflow: hidden
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
    transition: transform 0.3s ease
    text-align: left
    cursor: pointer
    
    &:hover
        transform: translateY(-5px)

.catalog-image
    flex: 1
    display: flex
    flex-direction: column
    width: 100%
    min-height: 180px
    border-radius: 10px
    position: relative
    background-size: cover
    background-position: center
    background-repeat: no-repeat

.catalog-info
    flex: 1
    display: flex
    flex-direction: column
    justify-content: flex-start
    width: 100%
    padding: 10px
    gap: 16px

.catalog-dataset
    font-size: 14px
    color: #707070
    font-family: 'Open Sans'
    text-align: left
    text-transform: uppercase
    font-weight: 400

.catalog-title
    font-size: 18px
    font-weight: 600
    height: calc(1.2em * 2)
    font-family: 'Poppins'
    color: #1E1E1E
    display: inline-block
    text-align: left
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    line-height: 1.2em

.catalog-description
  font-size: 14px
  color: #1e1e1e
  font-family: 'Open Sans'
  font-weight: 400
  line-height: 1.4
  height: calc( 1.4em * 2 )
  max-height: 2.8em
  overflow: hidden
  text-overflow: ellipsis
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical

.author
    position: relative
    width: 100%
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    gap: 16px
    text-align: left
    font-size: 14px
    color: #707070
    font-family: 'Open Sans'

.author-image
    width: 30px
    position: relative
    height: 30px
    border-radius: 30.5px
    object-fit: cover

.author-name
    gap: 8px
    font-weight: 700

.name-role
    margin: 0

.published
  font-size: 12px
  color: #666
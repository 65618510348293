.search-drop-down
    width: 100%
    position: relative
    border-radius: 5px
    border: 1px solid #5c96b5
    box-sizing: border-box
    height: 48px
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    padding: 0px 0px 0px 20px

    @media (max-width: 768px)
        padding: 0px 0px 0px 12px

    @media (max-width: 576px)
        padding: 0px 0px 0px 8px
        align-items: stretch

.input-field
    flex: 1
    position: relative
    font-weight: 300
    border: none
    outline: none
    font-size: 16px
    background: none
    color: #707070
    font-family: 'Open Sans'
    cursor: pointer

    @media (max-width: 768px)
        font-size: 14px

    @media (max-width: 576px)
        font-size: 12px

.dropdown-button
    align-self: stretch
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    padding: 0px 16px
    gap: 16px
    color: #fff
    border-left: 1px solid #136a97
    cursor: pointer

    @media (max-width: 768px)
        padding: 0 12px
        font-size: 14px

    @media (max-width: 576px)
        padding: 0 8px
        font-size: 12px

.dropdown-menu
    position: absolute
    top: 100%
    left: 0
    right: 0
    background-color: white
    border-bottom-left-radius: 5px
    border-bottom-right-radius: 5px
    border: 1px solid #5c96b5
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1)
    z-index: 10
    max-height: 200px
    overflow-y: auto
    transform: scaleY(0.95)
    transform-origin: top
    transition: opacify 0.3s ease, transform 0.3s ease

    &.open
        opacity: 1
        transform: scaleY(1)

    @media (max-width: 768px)
        max-height: 200px

    @media (max-width: 576px)
        max-height: 150px
        width: 100%

.dropdown-item
    padding: 10px
    cursor: pointer
    font-size: 16px
    color: #282828
    font-family: 'Open Sans'
    font-weight: 400

    &:hover
        background-color: #f0f0f0
        color: #005581

    @media (max-width: 768px)
        padding: 8px
        font-size: 14px

    @media (max-width: 576px)
        padding: 6px
        font-size: 12px
.profile-plan-summary-card
    width: 100%
    position: relative
    box-shadow: 2px 4px 27.1px 4px rgba(179, 206, 220, 0.44)
    border-radius: 8px
    background-color: #ffffff
    overflow: hidden
    flex-shrink: 0
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    padding: 40px 60px
    box-sizing: border-box
    gap: 20px
    text-align: left
    font-size: 16.18px
    font-weight: 400
    color: #707070
    font-family: 'Open Sans'
    transition: all 0.3s ease

    @media (max-width: 1024px)
        padding: 40px 40px
        font-size: 16px

    @media (max-width: 768px)
        padding: 40px 20px
        font-size: 16px
        box-shadow: 2px 2px 4px rgba(179, 206, 220, 0.44)

.plan-summary-title
    position: relative
    width: 100%
    margin: 0
    font-size: 24px
    font-family: 'Poppins'
    color: #005581
    text-align: left
    font-weight: 300

    @media (max-width: 1024px)
        font-size: 24px

    @media (max-width: 768px)
        font-size: 24px

.plan-name
    width: 100%
    position: relative
    font-size: 24px
    margin: 0
    font-weight: 700
    display: flex
    font-family: 'Poppins'
    text-transform: uppercase
    text-align: left
    align-items: center

    @media (max-width: 1024px)
        font-size: 24px
    
    @media (max-width: 768px)
        font-size: 24px

.plan-description
    width: 100%
    position: relative
    font-size: 16px
    font-weight: 400
    font-family: 'Open Sans'
    color: #707070
    text-align: left
    line-height: 1.5
    display: inline-block
    margin: 0

    @media (max-width: 1024px)
        font-size: 16px

    @media (max-width: 768px)
        font-size: 16px